import { Button, Col, Form, Input, Row, Select } from "antd";
import PhoneInput from "react-phone-input-2";
import { useAppSelector } from "../../redux/hooks";

const GroupsForm = ({ form, onCancel, onFinish }: any) => {
  const roles = useAppSelector((state) => state.usersReducer.roles);

  return (
    <Form
      className="form-allunox"
      layout="vertical"
      name="users"
      form={form}
      onFinish={onFinish}
    >
      <Row gutter={15}>
        <Col span={24}>
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, message: "Filed is required" }]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item name="id" hidden>
        <Input />
      </Form.Item>

      <Form.Item style={{ textAlign: "end", marginBottom: 0, marginTop: 10 }}>
        <Button
          onClick={onCancel}
          style={{ marginRight: 10 }}
          htmlType="button"
          type="default"
        >
          Cancel
        </Button>
        <Button htmlType="submit" type="primary">
          Save Group
        </Button>
      </Form.Item>
    </Form>
  );
};

export default GroupsForm;
