import { Button, Form, Image, Input, Select, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { RcFile } from "antd/es/upload";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../redux/hooks";
import { getSidesAll } from "../../redux/sides/actions";

/* eslint-disable react-hooks/exhaustive-deps */
const UsersForm = ({ form, onCancel, onFinish }: any) => {
  const dispatch = useAppDispatch();
  const id = Form.useWatch("id", form);
  const figure = Form.useWatch("figure", form);
  const [image, setImage] = useState("");
  const [sides, setSides] = useState<any>([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const data = await dispatch(getSidesAll());
    if (data) setSides(data);
  };

  const normFile = (e: any) => {
    if (e.file) {
      getBase64String(e.file, (e) => {
        setImage(e);
      });
    }
  };

  const getBase64String = (img: RcFile, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      callback(reader.result as string);
    });
    reader.readAsDataURL(img);
  };

  return (
    <Form
      className="form-allunox"
      layout="vertical"
      name="balconyType"
      form={form}
      onFinish={onFinish}
    >
      <Form.Item
        name="name"
        label="Name"
        rules={[{ required: true, message: "Filed is required" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="sides"
        label="Sides"
        rules={[{ required: true, message: "Filed is required" }]}
      >
        <Select
          mode="multiple"
          allowClear
          style={{ width: "100%" }}
          placeholder="Please select"
          options={sides?.map((side: any) => {
            return { value: side?.id, label: side?.name };
          })}
        />
      </Form.Item>

      <Form.Item name="figure" style={{ textAlign: "center" }}>
        <div style={{ fontSize: "16px", margin: 10 }}>
          <b>Image</b>
        </div>

        <Upload
          className="custom-upload"
          accept=".jpeg,.png"
          maxCount={1}
          listType="picture-card"
          showUploadList={false}
          beforeUpload={() => false}
          onChange={normFile}
        >
          {image || figure ? (
            <Image preview={false} src={image || figure} />
          ) : (
            <>
              <UploadOutlined style={{ marginRight: 5 }} />
              Upload
            </>
          )}
        </Upload>
      </Form.Item>

      <Form.Item name="id" hidden>
        <Input />
      </Form.Item>

      <Form.Item style={{ textAlign: "end", marginBottom: 0, marginTop: 10 }}>
        <Button
          onClick={onCancel}
          style={{ marginRight: 10 }}
          htmlType="button"
          type="default"
        >
          Cancel
        </Button>
        <Button htmlType="submit" type="primary">
          {id ? "Update" : "Save"} Balcony Type
        </Button>
      </Form.Item>
    </Form>
  );
};

export default UsersForm;
