export const ROLES = {
  ROLE_SUPER_ADMIN: "superadmin",
  ROLE_RESELLER: "ROLE_RESELLER",
  ROLE_ADMIN_USER: "admin",
  ROLE_USER: "user",
};

export const ROLES_LABEL: any = {
  ROLE_SUPER_ADMIN: "Super Admin",
  ROLE_RESELLER: "Reseller",
  ROLE_ADMIN_USER: "Admin User",
  ROLE_USER: "Reseller User",
};
