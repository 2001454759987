import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Form, Image, Modal, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  getDefaultMeasures,
  updateDefaultMeasures,
} from "../../redux/defaultMeasures/actions";
import DefaultMeasuresForm from "./defaultMeasuresForm";

/* eslint-disable react-hooks/exhaustive-deps */
const DefaultMeasures = () => {
  const [visibleForm, setVisibleForm] = useState(false);
  const [defaultMeasure, setDefaultMeasure] = useState<any>({});

  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const defaultMeasuresState = useAppSelector(
    (state) => state.defaultMeasuresReducer.defaultMeasures
  );
  const loaderDefaultMeasures = useAppSelector(
    (state) => state.defaultMeasuresReducer.loaderDefaultMeasures
  );

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (defaultMeasure.id) {
      form.setFieldsValue(defaultMeasure);
    }
  }, [defaultMeasure]);
  useEffect(() => {
    formatForm();
  }, [defaultMeasuresState]);

  const getData = (page = 1) => {
    dispatch(getDefaultMeasures(page));
    formatForm();
  };

  const showModalForm = (defaultMeasure: any) => {
    if (defaultMeasure?.id) {
      setDefaultMeasure(defaultMeasure);
    }

    setVisibleForm(true);
  };

  const actionsMarkup = (data: any) => {
    return (
      <div className="table-actions">
        <>
          <Button
            className="color-primary"
            onClick={() => showModalForm(data)}
            type="text"
          >
            <FontAwesomeIcon icon="edit" />
          </Button>
        </>
      </div>
    );
  };

  const colums: ColumnsType<any> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      responsive: ["sm"],
    },

    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      responsive: ["sm"],
    },
  ];

  const onFinish = (data: any) => {
    /*     let formData: any = new FormData();
    Object.keys(data).forEach((name) => {
      formData.append(name, data[name]);
    }); */

    if (defaultMeasuresState?.id) {
      dispatch(updateDefaultMeasures(data, defaultMeasuresState?.id));
    }

    handleCancel();
  };

  const handleCancel = () => {
    getData();

    setVisibleForm(false);
  };

  const formatForm = () => {
    form.setFieldsValue(defaultMeasuresState);
  };
  return (
    <Card title="Default measurements">
      <div className="table-responsive">
        <DefaultMeasuresForm
          form={form}
          onFinish={onFinish}
          onCancel={handleCancel}
        />
      </div>

      <Modal
        title={<>Update Default Measures</>}
        open={visibleForm}
        footer={<></>}
        centered
        onCancel={handleCancel}
        destroyOnClose
        width="800px"
      >
        <div style={{ textAlign: "center" }}>
          <br />
          <Image
            preview={false}
            style={{ width: "100%" }}
            src={require("../../static/img/footer-logo.png")}
            alt="..."
          />
          <br />
          <br />
        </div>

        <DefaultMeasuresForm
          form={form}
          onFinish={onFinish}
          onCancel={handleCancel}
        />
      </Modal>
    </Card>
  );
};

export default DefaultMeasures;
