import WithAuthLayout from "../layouts/withAuthLayout";
import Login from "../pages/auth/login";

export const auth = [
  {
    path: "/",
    element: <WithAuthLayout />,
    children: [
      { index: true, element: <Login /> },
      { path: "login", element: <Login /> },
    ],
  },
];
