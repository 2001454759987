import { HomeOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Card,
  Col,
  Form,
  message,
  Row,
  Upload,
  UploadProps,
  Typography,
  Spin,
  Drawer,
  Tag,
  Collapse,
  Image,
  Tooltip,
  Modal,
  Progress,
  Breadcrumb,
  Divider,
  Dropdown,
  MenuProps,
  Popconfirm,
} from "antd";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useParams, useLocation, useNavigate, Outlet } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  cleanProject,
  deleteProject,
  getProjectById,
  saveProject,
  updateProject,
  getProjectPDF,
  downloadPlans,
} from "../../redux/projects/actions";
import { getPDF } from "../../utils/buildPDF";
import { getAddressFormat } from "../../utils/getFormats";
import ProjectsForm from "./projectsForm";
import ProjectsFormData from "./projectsFormData";
import { useAuthUser } from "react-auth-kit";
import { ROLES } from "../../utils/roles";
import Cookies from "js-cookie";

const { Dragger } = Upload;
const { Paragraph } = Typography;
const { Panel } = Collapse;

/* eslint-disable react-hooks/exhaustive-deps */
const ProjectsInfo = () => {
  const { id, version } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [form] = Form.useForm();
  const [formData] = Form.useForm();
  const [visibleForm, setVisibleForm] = useState(false);
  const [loadProcess, setLoadProcess] = useState({ total: 0, process: 0 });

  const project = useAppSelector((state) => state.projectsReducer.project);
  const { floors } = useAppSelector((state) => state.projectsReducer.project);
  const loaderProjects = useAppSelector(
    (state) => state.projectsReducer.loaderProjects
  );
  const authUser = useAuthUser();

  const user = authUser();
  const [loaderPlan, setLoaderVisiblePlan] = useState(false);
  const [visiblePlan, setVisiblePlan] = useState(false);
  const [pdfInfo, setPdfInfo] = useState<any>([]);
  const [layers, setLayers] = useState<any>({
    1: { active: true, label: "Glass", value: "glass" },
    2: { active: true, label: "Center to Center ", value: "center_to_center" },
    3: {
      active: true,
      label: "Inputs",
      value: "inputs",
    },
    4: { active: true, label: "Railing /wall Measurement", value: "railing" },
  });
  const items: MenuProps["items"] = [
    {
      label: (
        <Popconfirm
          title={
            <>
              {" "}
              <FontAwesomeIcon icon="trash" size="sm" /> Delete the Proyect
            </>
          }
          description={
            <>
              {" "}
              Are you sure to delete <b>({project?.project_id})</b>?
            </>
          }
          okText="Yes"
          cancelText="No"
          onCancel={() => {}}
          onConfirm={async () => {
            const success: any = await dispatch(deleteProject(project.id));

            if (success) {
              navigate("/dashboard/projects");
            }
          }}
        >
          <Button danger>Delete</Button>
        </Popconfirm>
      ),
      key: "0",
    },
  ];
  /* 
  useEffect(() => {
    if (visiblePlan) setData();
  }, [layers, visiblePlan]); */

  /*   const setData = async () => {
    setLoaderVisiblePlan(true);
    setPdfInfo(await getPDF(1, layers, null, project, setLoadProcess));

    setLoadProcess({ total: 0, process: 0 });
    setLoaderVisiblePlan(false);
  }; */

  useEffect(() => {
    dispatch(getProjectById(id, { version: version }));

    return () => {
      dispatch(cleanProject());
    };
  }, [id]);

  const isActive = (path: any) => {
    return path === location.pathname.split("/").slice(-1)[0] ? "actived" : "";
  };

  const onFinish = async (data: any) => {
    if (!data.id) {
      dispatch(saveProject(data));
    } else {
      await dispatch(updateProject({ ...project, ...data }, project.id));
      dispatch(getProjectById(id, { version: version }));
    }

    handleCancel();
  };

  const onFinishData = async (data: any) => {
    delete project.project_address.id;
    const params = {
      ...project,
      ...project.project_address,
      default_measures: data,
    };

    await dispatch(updateProject(params, project.id));
    dispatch(getProjectById(id, { version: version }));

    handleCancel();
  };

  const handleCancel = () => {
    setVisibleForm(false);

    setTimeout(() => {
      form.resetFields();
      formData.resetFields();
    }, 300);
  };

  const downloadProjectPDF = async () => {
    const layersSelected = formatLayers(layers);
    const pdf: any = await dispatch(
      getProjectPDF(project.id, {
        layers: layersSelected,
        version: version,
      })
    );
    setPdfInfo(URL.createObjectURL(pdf));
    /*     window.open(URL.createObjectURL(pdf));
     */
  };
  const formatLayers = (layers: any) => {
    const layersArray = Object.keys(layers).map((layer) => {
      return layers[layer].active ? layers[layer].value : "";
    });
    return layersArray.filter((layer: any) => layer !== "");
  };

  const onDownloadPlans = async () => {
    const data = (await dispatch(downloadPlans(project.id))) as any;
    window.open(data?.data);
  };

  const props: UploadProps = {
    name: "file",
    multiple: false,
    action:
      "https://api-allunox.propulsoft.dev/api/projects/" +
      project.id +
      "/upload",
    method: "POST",
    headers: {
      Authorization: `${Cookies.get("_auth_type") || ""} ${
        Cookies.get("_auth") || ""
      }`,
    },

    accept: ".pdf, .dst, .dwf, .dwg, .dwfx, .dwt, .dxb, .dxf",
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  return (
    <Spin spinning={loaderProjects}>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/dashboard/projects">
            <HomeOutlined />
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{project?.project_id}</Breadcrumb.Item>
      </Breadcrumb>
      <Divider />

      <Row gutter={24}>
        <Col span={24} md={16} style={{ marginBottom: "30px" }}>
          <Card
            extra={
              user?.role === ROLES.ROLE_SUPER_ADMIN ||
              user?.role === ROLES.ROLE_ADMIN_USER ? (
                <Button
                  type="text"
                  onClick={() => {
                    setVisibleForm(true);

                    form.setFieldsValue({
                      id: project?.id,
                      project_id: project?.project_id,
                      engineering: project?.engineering,
                      address_line_1: project?.project_address.address_line_1,
                      address_line_2: project?.project_address.address_line_2,
                      province: project?.project_address.province,
                      city: project?.project_address.city,
                      postal_code: project?.project_address.postal_code,
                      client: project?.client,
                      floors: project?.floors?.length,
                      user_id: project?.assigned_to,
                      group_id: project?.group_id,
                    });

                    formData.setFieldsValue(project?.default_measures);
                  }}
                >
                  <FontAwesomeIcon style={{ paddingRight: 10 }} icon="edit" />{" "}
                  Edit
                </Button>
              ) : (
                <></>
              )
            }
          >
            <Row gutter={24}>
              <Col span={24} sm={12}>
                <b> Project ID</b>
                <Paragraph mark className="text_1">
                  {project?.project_id}
                </Paragraph>
                <b>Address</b>
                <Paragraph
                  strong
                  className="text_1"
                  style={{ maxWidth: "16rem" }}
                >
                  {getAddressFormat(project?.project_address)}
                </Paragraph>
                <b>Floors </b>
                <Paragraph className="text_1">
                  {project?.floors?.length}
                </Paragraph>

                <b>No Balconies </b>
                <Paragraph className="text_1">
                  {project?.floors?.reduce(
                    (partialSum: number, a: any) =>
                      partialSum + a.balconies.length,
                    0
                  )}
                </Paragraph>
                {project?.engineering ? <b>Engineering</b> : <></>}
                <br />
                <b> Client</b>
                <Paragraph mark className="text_1">
                  {project?.client}
                </Paragraph>
              </Col>
              <Col span={24} sm={12}>
                <div style={{ color: "black" }} className="btn-link">
                  <b>Assign Array</b>
                </div>
                <div
                  style={{ color: "black", marginTop: "12px" }}
                  className="btn-link"
                >
                  <b>Request new measurement (Date)</b>
                </div>
                <br />
                <br />

                <Tooltip title="Download PDF of balconies">
                  <Image
                    onClick={async () => {
                      setVisiblePlan(true);
                      downloadProjectPDF();
                    }}
                    className="custom-action"
                    width={35}
                    preview={false}
                    src={require("../../static/img/descargar-pdf.png")}
                  />
                </Tooltip>
              </Col>
            </Row>
          </Card>
        </Col>

        <Col span={24} md={8}>
          <Card
            bodyStyle={{
              padding: "0",
              color: "rgb(3, 34, 89)",
              paddingBottom: "2rem",
            }}
            cover={
              <div
                style={{
                  textAlign: "center",
                  cursor: "pointer",
                }}
              >
                <Dragger
                  {...props}
                  style={{
                    borderBottomStyle: "solid",
                    borderBottomRightRadius: 0,
                    borderBottomLeftRadius: 0,
                  }}
                >
                  <div className="ant-upload-drag-icon">
                    <div
                      style={{
                        background: "rgb(3, 34, 89)",
                        maxWidth: "110px",
                        borderRadius: "10px",
                        margin: "auto",
                        textAlign: "center",
                        padding: "10px",
                      }}
                    >
                      <img
                        style={{ maxWidth: "70px", margin: "auto" }}
                        alt="..."
                        src={require("../../static/img/plan.png")}
                      />
                    </div>
                  </div>
                  <p
                    style={{ fontSize: "13px", color: "rgb(3, 34, 89)" }}
                    className="ant-upload-text"
                  >
                    Click or drag plans to upload
                  </p>
                </Dragger>
              </div>
            }
          ></Card>
          {project?.file && (
            <Button
              type="primary"
              block
              style={{ marginTop: "20px" }}
              onClick={onDownloadPlans}
            >
              Download plans
            </Button>
          )}
        </Col>
        <Col span={24}>
          <Card
            className="card-project-info"
            bodyStyle={{ padding: "40px 0" }}
            title={
              <Row>
                {/*    <Col
                  className={`begin ${isActive("details") || isActive(id)}`}
                  onClick={() => {
                    navigate("details");
                  }}
                  span={12}
                  sm={8}
                >
                  Details
                </Col> */}
                <Col
                  className={`middle ${isActive("measurements")}`}
                  onClick={() => {
                    if (floors.length > 0) {
                      navigate("measurements");
                    } else {
                      message.warning("You must add a floor");
                    }
                  }}
                  span={12}
                  sm={12}
                >
                  Measurements
                </Col>
                <Col
                  className={`end ${isActive("installation-progress")}`}
                  onClick={() => {
                    if (floors.length > 0) {
                      navigate("installation-progress");
                    } else {
                      message.warning("You must add a floor");
                    }
                  }}
                  span={24}
                  sm={12}
                >
                  Installation Progress
                </Col>
              </Row>
            }
          >
            <Outlet />
          </Card>
        </Col>

        <Drawer
          open={visibleForm}
          onClose={handleCancel}
          mask={false}
          width={400}
          destroyOnClose
          contentWrapperStyle={{ boxShadow: "1px 1px 1px 1px" }}
          bodyStyle={{ paddingLeft: "5px", paddingRight: "5px" }}
          title={
            <>
              Project ID:{" "}
              <Tag style={{ fontSize: 16 }}>{project?.project_id}</Tag>
            </>
          }
        >
          <Dropdown menu={{ items }} placement="bottom">
            <Button
              onClick={(e) => e.preventDefault()}
              type="text"
              icon={<FontAwesomeIcon icon="gear" />}
            />
          </Dropdown>

          <Collapse
            className="custom-collapse"
            defaultActiveKey={[2]}
            ghost
            expandIconPosition="end"
          >
            <Panel header="Project Information" key={1}>
              <ProjectsForm
                form={form}
                onFinish={onFinish}
                onCancel={handleCancel}
              />
            </Panel>
            <Panel className="custom-collapse" header="Project Data" key={2}>
              <ProjectsFormData
                form={formData}
                onFinish={onFinishData}
                onCancel={handleCancel}
              />
            </Panel>
          </Collapse>
        </Drawer>
      </Row>

      <Modal
        title={"Project"}
        open={visiblePlan}
        centered
        destroyOnClose
        footer={<></>}
        onCancel={() => {
          setVisiblePlan(false);
          setLoaderVisiblePlan(false);
          setLoadProcess({ total: 0, process: 0 });
          setPdfInfo("");
        }}
        width="1200px"
      >
        <div style={{ textAlign: "center", marginTop: 20 }}>
          <Row>
            <Col sm={6}>
              <div style={{ marginBottom: 10, textAlign: "start" }}>
                {Object.keys(layers).map((layer, i) => {
                  return (
                    <div
                      key={i}
                      onClick={() => {
                        if (!loaderPlan) {
                          layers[layer].active = !layers[layer].active;
                          downloadProjectPDF();
                          setLayers({ ...layers });
                        }
                      }}
                      style={{
                        margin: "5px 20px",
                        fontSize: 16,
                        cursor: "pointer",
                      }}
                    >
                      <FontAwesomeIcon
                        style={{ paddingRight: 5 }}
                        icon={layers[layer].active ? "square-check" : "square"}
                      />
                      {layers[layer].label}
                    </div>
                  );
                })}
              </div>
            </Col>
            <Col sm={18}>
              {loaderPlan && (
                <Progress
                  percent={parseFloat(
                    ((loadProcess.process * 100) / loadProcess.total).toFixed(2)
                  )}
                  status="active"
                />
              )}
              <Spin spinning={loaderPlan}>
                {visiblePlan && (
                  <iframe
                    style={{ minHeight: "900px", height: "100%" }}
                    title="frame"
                    src={pdfInfo ? pdfInfo : undefined}
                    height="100%"
                    width="100%"
                  />
                )}
              </Spin>
            </Col>
          </Row>
        </div>
      </Modal>
    </Spin>
  );
};

export default ProjectsInfo;
