import { notification } from "antd";
import axios from "axios";
import Cookies from "js-cookie";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const api = axios.create({
  baseURL: API_ENDPOINT + "/api",
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: false,
});

//api.defaults.timeout = 2500;

api.interceptors.request.use(
  (config) => {
    const requestConfig = config;
    const { headers } = config;
    requestConfig.headers = {
      ...headers,
      Authorization: `${Cookies.get("_auth_type") || ""} ${
        Cookies.get("_auth") || ""
      }`,
    };

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (err) => {
    const data = await Promise.resolve({ error: err });
    if (data?.error?.response.status === 401) {
      /* redirect to login */
      notification.error({
        message: "Unauthorized!!!",
        description: "Please login to continue.",
        duration: 8,
      });
      window.location.href = "/";
      return;
    }
    if (!data?.error?.response) {
      // network error
      notification.error({
        message: `${data?.error?.message}!!!`,
        description: "Check your connection or try again later.",
        duration: 8,
      });
      return data?.error?.message;
    } else {
      let message = `Something went wrong!!!`;

      notification.warning({
        message,
        description:
          data?.error?.response?.data?.message ||
          data?.error?.response?.data?.messages ||
          data?.error?.response?.data?.messages
            ?.map((el: any) => el.info)
            .toString(),
        duration: 8,
      });
    }

    return data?.error?.response;
  }
);

export default api;
