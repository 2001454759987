import Styled from "styled-components";
const SupportTopWrap = Styled.div`
  border-radius: 10px;
  box-shadow: 0 5px 20px #9299B810;
  margin-bottom: 50px;
  .sDash-support-container{
    max-width: 1110px;
    margin: 0 auto;
    @media only screen and (max-width: 1599px){
      max-width: 990px;
    }
    @media only screen and (max-width: 1399px){
      max-width: 790px;
    }


    .text{
      color: white;

      button {
        color: white;
        font-weight: bold;
        padding: 4px;
      }
    }
  }
  .sDash_support-content{
    .sDash_support-content__title{
      font-size: 30px;
      font-weight: 600;
      margin-bottom: 10px;
      color: white;
      margin-top: 10px;
      @media only screen and (max-width: 767px){
        font-size: 26px;
      }
    }
    p{
      color: white;
      font-size: 16px;
      font-weight: 600;
    }
  }
`;

export { SupportTopWrap };
