import api from "../../api";

export const actions = {
  SUCCESS_DEFAULT_MEASURES: "SUCCESS_DEFAULT_MEASURES",
  CLEAN_DEFAULT_MEASURES: "CLEAN_DEFAULT_MEASURES",
  ACTION_BEGIN: "ACTION_BEGIN",
  ERROR: "ERROR",
};

const path = "/default-measures";

const begin = (data: any) => {
  return (dispatch: any) => {
    dispatch({
      type: actions.ACTION_BEGIN,
      data,
    });
  };
};

const error = (err: any) => {
  return { type: actions.ERROR, err };
};

const updateDefaultMeasures = (data: any, id: number) => {
  return async (dispatch: any) => {
    try {
      dispatch(begin({ loaderDefaultMeasures: true }));
      const response = await api.put(`${path}`, data);
      dispatch(begin({ loaderDefaultMeasures: false }));

      if (response.status >= 200 && response.status <= 201) {
        dispatch(getDefaultMeasures());
        return true;
      }

      dispatch(error(response?.data?.message));
      return response?.data?.message;
    } catch (err) {
      dispatch(error(err));
      return err;
    }
  };
};

const getDefaultMeasures = (page?: number) => {
  return async (dispatch: any) => {
    try {
      dispatch(begin({ loaderDefaultMeasures: true }));
      const response = await api.get(`${path}${page ? "?page=" + page : ""}`);
      dispatch(begin({ loaderDefaultMeasures: false }));

      if (response.status >= 200 && response.status <= 201) {
        const { data } = response;

        dispatch({
          type: actions.SUCCESS_DEFAULT_MEASURES,
          data: data.data,
        });
        return;
      }

      dispatch(error(response?.data?.message));
      return response?.data?.message;
    } catch (err) {
      dispatch(error(err));
      return err;
    }
  };
};

const cleanDefaultMeasures = () => {
  return async (dispatch: any) => {
    dispatch({ type: actions.CLEAN_DEFAULT_MEASURES });
  };
};

export {
  begin,
  updateDefaultMeasures,
  getDefaultMeasures,
  cleanDefaultMeasures,
};
