import React, { useEffect, useState } from "react";
import "react-multi-carousel/lib/styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Avatar,
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  Descriptions,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  Spin,
  Typography,
} from "antd";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  addVersionBalcony,
  saveBalconyMeasurement,
  getBalconyTypes,
  updateInstallationProcess,
} from "../../../redux/projects/actions";
import Carousel from "react-multi-carousel";

const { Panel } = Collapse;
const { Title, Paragraph } = Typography;

/* eslint-disable react-hooks/exhaustive-deps */
const InstallationProgress = () => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [formEdit] = Form.useForm();
  //const floor = Form.useWatch("floorId", form);
  const [balconyTypes, setBalconyTypes] = useState<any>({});
  const [balconyMeasurements, setBalconyMeasurements] = useState<any>({});
  const [visibleForm, setVisibleForm] = useState(false);
  const [visibleFormEdit, setVisibleFormEdit] = useState(false);
  const [visibleReadInfo, setVisibleReadInfo] = useState(false);

  const { floors } = useAppSelector((state) => state.projectsReducer.project);
  const loaderFloors = useAppSelector(
    (state) => state.projectsReducer.loaderFloors
  );
  const loaderBalconies = useAppSelector(
    (state) => state.projectsReducer.loaderBalconies
  );

  useEffect(() => {
    getCatalog();
  }, []);

  const getCatalog = async () => {
    const data = await dispatch(getBalconyTypes(1, 100));
    if (data) setBalconyTypes(data);
  };

  const handleSaveBalconyMeasurement = async (data: any) => {
    const status: any = await dispatch(
      saveBalconyMeasurement(data, balconyMeasurements?.id)
    );
    if (status >= 200 && status <= 201) {
      handleCancelEdit();
    }
  };

  const handleAddVersion = async (data: any) => {
    await dispatch(addVersionBalcony(data.floorId));
    handleCancel();
  };

  const handleCancel = () => {
    form.resetFields();
    setVisibleForm(false);
  };

  const handleCancelEdit = () => {
    formEdit.resetFields();
    setBalconyMeasurements({});
    setVisibleFormEdit(false);
    setVisibleReadInfo(false);
  };
  const getCurrentProgress = (balconies: any) => {
    let count = 0;
    balconies?.forEach((el: any) => {
      if (el.installment == 1) {
        count++;
      }
    });
    return count;
  };
  const totalBalconies = () => {
    let count = 0;
    floors?.forEach((el: any) => {
      count += el.balconies.length;
    });
    return count;
  };
  const totalProgress = () => {
    let count = 0;
    floors?.forEach((el: any) => {
      count += getCurrentProgress(el.balconies);
    });
    return count;
  };
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 60,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 50,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      paritialVisibilityGutter: 30,
    },
  };
  const onUpdateProcess = (value: any, id: number) => {
    dispatch(updateInstallationProcess({ installment: value }, id));
  };

  return (
    <Spin spinning={loaderFloors}>
      <Title
        style={{
          marginTop: 0,
          marginLeft: 30,
        }}
        level={3}
      >
        Total progress {floors ? totalProgress() : 0}/ {totalBalconies()}
      </Title>
      <Collapse
        className="accordeon-allunox"
        defaultActiveKey={["0"]}
        bordered={false}
        accordion
        ghost
      >
        {floors?.map((el: any, i: number) => (
          <Panel
            className={
              i === 0 ? "first" : i === floors.length - 1 ? "last" : ""
            }
            showArrow={false}
            header={
              <>
                <Title style={{ marginTop: 0 }} level={3}>
                  {el.name}
                </Title>
                <Paragraph>
                  No. Balconies {el?.balconies?.length}
                  <b style={{ marginLeft: "20px" }}>
                    Progress {getCurrentProgress(el.balconies)}/
                    {el?.balconies.length}
                  </b>
                </Paragraph>
              </>
            }
            key={i}
          >
            <Card bordered={false} className="noShadown">
              <Carousel ssr responsive={responsive}>
                {el?.balconies?.map((balcony: any, i: number) => {
                  return (
                    <div>
                      {/*   <Avatar
                        style={{ marginRight: "10px" }}
                        shape="square"
                        icon={<FontAwesomeIcon icon="grip-lines" />}
                      /> */}
                      <Checkbox
                        style={{ marginRight: "10px" }}
                        onChange={(value) =>
                          onUpdateProcess(value.target.checked, balcony.id)
                        }
                        checked={balcony.installment == 1 ? true : false}
                      />
                      {balcony?.full_name}
                      <br />
                      <br />

                      {/*      <Descriptions column={1}>
                        <Descriptions.Item label="Balcony Type">
                          --
                        </Descriptions.Item>
                        <Descriptions.Item label="First Update">
                          --
                        </Descriptions.Item>
                        <Descriptions.Item label="Tech">--</Descriptions.Item>
                        <Descriptions.Item label="Latest Update">
                          --
                        </Descriptions.Item>
                        <Descriptions.Item label="Tech">--</Descriptions.Item>
                      </Descriptions> */}
                    </div>
                  );
                })}
              </Carousel>
            </Card>
          </Panel>
        ))}
      </Collapse>
      <Modal
        title={<>Add New Version</>}
        open={visibleForm}
        footer={<></>}
        onCancel={handleCancel}
      >
        <div style={{ marginTop: 30 }}>
          <Form
            className="form-allunox"
            name="version"
            layout="vertical"
            form={form}
            onFinish={handleAddVersion}
          >
            <Form.Item
              label="Floor"
              name="floorId"
              rules={[{ required: true, message: "This field is required" }]}
            >
              <Select
                onChange={() => form.setFieldValue("balconyId", "")}
                options={floors?.map((el: any) => {
                  return { value: el.id, label: el.name };
                })}
              />
            </Form.Item>
            {/*<Form.Item
              label="Balcony"
              name="balconyId"
              rules={[{ required: true, message: "This field is required" }]}
            >
              <Select
                options={floors
                  ?.find((el: any) => el.id === floor)
                  ?.balconies?.map((el: any) => {
                    return { value: el.id, label: el.name };
                  })}
              />
                </Form.Item>*/}
            <Form.Item style={{ textAlign: "right" }}>
              <br />
              <Button size="large" htmlType="submit" type="primary">
                Save
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
      <Modal
        title={<></>}
        open={visibleFormEdit}
        centered
        footer={<></>}
        onCancel={handleCancelEdit}
        width="700px"
      >
        <Spin spinning={loaderBalconies}>
          <div style={{ marginTop: 30 }}>
            <Form
              className="form-allunox"
              name="version"
              layout="vertical"
              form={formEdit}
              onFinish={handleSaveBalconyMeasurement}
            >
              <Form.Item
                style={{ textAlign: "center" }}
                label="Balcony Type"
                name="balcony_type_id"
                rules={[{ required: true, message: "This field is required" }]}
              >
                <Radio.Group className="radio-custom">
                  {balconyTypes?.data?.map((el: any, i: number) => (
                    <Radio key={i} value={el.id}>
                      <Avatar size={74} shape="square" />
                      <br />
                      {el.name}
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>

              <Row gutter={24}>
                <Col span={24} sm={12}>
                  <Form.Item
                    style={{ marginBottom: "39px" }}
                    label="Measurement before Brick"
                    name="measurement_before_brick"
                    rules={[
                      { required: true, message: "This field is required" },
                    ]}
                  >
                    <Radio.Group>
                      <Radio value={1}>Yes</Radio>
                      <Radio value={0}>No</Radio>
                    </Radio.Group>
                  </Form.Item>

                  <Form.Item
                    label="Brick thickness"
                    name="brick_thickness"
                    rules={[
                      { required: true, message: "This field is required" },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Max Spacing of Intermediate"
                    name="max_spacing_intermediate"
                    rules={[
                      { required: true, message: "This field is required" },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Distance from Slab Edge"
                    name="distance_slab_edge"
                    rules={[
                      { required: true, message: "This field is required" },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Distance from Wall to Glass"
                    name="distance_wall_glass"
                    rules={[
                      { required: true, message: "This field is required" },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={24} sm={12}>
                  {/*<Form.Item
                    label="Start Date"
                    name="startDate"
                    rules={[
                      { required: true, message: "This field is required" },
                    ]}
                  >
                    <Input type="date" />
                  </Form.Item>*/}
                  <Form.Item
                    label="Spacing Between Glass"
                    name="spacing_between_glass"
                    rules={[
                      { required: true, message: "This field is required" },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Spacing Between Glass and Corner"
                    name="spacing_between_glass_corner"
                    rules={[
                      { required: true, message: "This field is required" },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Height of Glass"
                    name="height_glass"
                    rules={[
                      { required: true, message: "This field is required" },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Center of Intermediate Post to Wall"
                    name="center_intermediate_post_wall"
                    rules={[
                      { required: true, message: "This field is required" },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Center of Intermediate Post to Edge"
                    name="center_intermediate_post_edge"
                    rules={[
                      { required: true, message: "This field is required" },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item label="Notes" name="notes">
                <Input.TextArea rows={5} />
              </Form.Item>
              <Form.Item style={{ textAlign: "center" }}>
                <Button
                  disabled={loaderBalconies}
                  size="large"
                  htmlType="submit"
                  type="primary"
                >
                  Save
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Spin>
      </Modal>
      <Modal
        title={"Balcony Measurements"}
        open={visibleReadInfo}
        centered
        footer={<></>}
        onCancel={handleCancelEdit}
        width="700px"
      >
        <div style={{ marginTop: 30 }}>
          <Descriptions bordered column={1} labelStyle={{ maxWidth: "80px" }}>
            <Descriptions.Item label="Balcony Type">
              {
                balconyTypes?.data?.find(
                  (el: any) => el.id === balconyMeasurements?.balcony_type_id
                )?.name
              }
            </Descriptions.Item>
            <Descriptions.Item label="Measurement before Brick">
              {balconyMeasurements?.measurement_before_brick ? "true" : "false"}
            </Descriptions.Item>
            <Descriptions.Item label="Brick thickness">
              {balconyMeasurements?.brick_thickness}
            </Descriptions.Item>
            <Descriptions.Item label="Max Spacing of Intermediate">
              {balconyMeasurements?.max_spacing_intermediate}
            </Descriptions.Item>
            <Descriptions.Item label="Distance from Slab Edge">
              {balconyMeasurements?.distance_slab_edge}
            </Descriptions.Item>
            <Descriptions.Item label="Distance from Wall to Glass">
              {balconyMeasurements?.distance_wall_glass}
            </Descriptions.Item>
            <Descriptions.Item label="Spacing Between Glass">
              {balconyMeasurements?.spacing_between_glass}
            </Descriptions.Item>
            <Descriptions.Item label="Spacing Between Glass and Corner">
              {balconyMeasurements?.spacing_between_glass_corner}
            </Descriptions.Item>
            <Descriptions.Item label="Height of Glass">
              {balconyMeasurements?.height_glass}
            </Descriptions.Item>
            <Descriptions.Item label="Center of Intermediate Post to Wall">
              {balconyMeasurements?.center_intermediate_post_wall}
            </Descriptions.Item>
            <Descriptions.Item label="Center of Intermediate Post to Edge">
              {balconyMeasurements?.center_intermediate_post_edge}
            </Descriptions.Item>
            <Descriptions.Item label="Notes">
              {balconyMeasurements?.notes}
            </Descriptions.Item>
          </Descriptions>
        </div>
      </Modal>
    </Spin>
  );
};

export default InstallationProgress;
