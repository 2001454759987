import { combineReducers } from "redux";
import authReducer from "./auth/reducers";
import userReducer from "./user/reducers";
import usersReducer from "./users/reducers";
import balconyTypesReducer from "./balconyType/reducers";
import projectsReducer from "./projects/reducers";
import defaultMeasuresReducer from "./defaultMeasures/reducers";
import sidesReducer from "./sides/reducers";
import groupsReducer from "./groups/reducers";
const rootReducers = combineReducers({
  authReducer,
  userReducer,
  usersReducer,
  projectsReducer,
  balconyTypesReducer,
  defaultMeasuresReducer,
  sidesReducer,
  groupsReducer,
});

export default rootReducers;
