import { Button, Col, Form, Input, Row, Select } from "antd";
import PhoneInput from "react-phone-input-2";
import { useAppSelector } from "../../redux/hooks";

const UsersForm = ({ form, onCancel, onFinish }: any) => {
  const roles = useAppSelector((state) => state.usersReducer.roles);
  const groups = useAppSelector((state) => state.groupsReducer.groups) || [];

  return (
    <Form
      className="form-allunox"
      layout="vertical"
      name="users"
      form={form}
      onFinish={onFinish}
    >
      <Row gutter={15}>
        <Col span={12}>
          <Form.Item
            name="first_name"
            label="First name"
            rules={[{ required: true, message: "Filed is required" }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="last_name"
            label="Last name"
            rules={[{ required: true, message: "Filed is required" }]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        name="phone_number"
        label="Phone"
        rules={[{ required: true, message: "Filed is required", min: 11 }]}
      >
        <PhoneInput
          inputClass="ant-input"
          enableAreaCodes={true}
          country={"ca"}
          onlyCountries={["ca", "us", "mx"]}
        />
      </Form.Item>
      <Form.Item
        name="email"
        label="Email"
        rules={[
          { type: "email", message: "Please enter a correct email" },
          { required: true, message: "Filed is required" },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="role"
        label="Role"
        rules={[{ required: true, message: "Filed is required" }]}
      >
        <Select>
          {roles?.map((item: any, i: number) => (
            <Select.Option key={i} value={item}>
              {item}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="group_id"
        label="Group"
        rules={[{ required: true, message: "Filed is required" }]}
      >
        <Select>
          {groups?.map((item: any, i: number) => (
            <Select.Option key={i} value={item.id}>
              {item.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="id" hidden>
        <Input />
      </Form.Item>

      <Form.Item style={{ textAlign: "end", marginBottom: 0, marginTop: 10 }}>
        <Button
          onClick={onCancel}
          style={{ marginRight: 10 }}
          htmlType="button"
          type="default"
        >
          Cancel
        </Button>
        <Button htmlType="submit" type="primary">
          Save User
        </Button>
      </Form.Item>
    </Form>
  );
};

export default UsersForm;
