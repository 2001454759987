import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Card,
  Descriptions,
  Divider,
  Form,
  Image,
  Modal,
  Popconfirm,
  Table,
} from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  getGroups,
  saveGroup,
  updateGroup,
  getGroupById,
  deleteGroup,
  cleanGroup,
} from "../../redux/groups/actions";
import GroupsForm from "./groupsForm";

/* eslint-disable react-hooks/exhaustive-deps */
const Groups = () => {
  const [visibleForm, setVisibleForm] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const groups = useAppSelector((state) => state.groupsReducer.groups);
  const group = useAppSelector((state) => state.groupsReducer.group);
  const loaderGroups = useAppSelector(
    (state) => state.groupsReducer.loaderGroups
  );

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (group.id) {
      const data: any = {
        id: group.id,
        name: group.name,
      };

      form.setFieldsValue(data);
    }
  }, [group]);

  const getData = (page = 1) => {
    dispatch(getGroups(page));
  };

  const showModalForm = (id: number) => {
    if (id) {
      dispatch(getGroupById(id));
    }

    setVisibleForm(true);
  };

  const actionsMarkup = (id: number) => {
    return (
      <div className="table-actions">
        <>
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => dispatch(deleteGroup(id))}
          >
            <Button type="text">
              <FontAwesomeIcon icon="trash-alt" />
            </Button>
          </Popconfirm>

          <Button
            className="color-primary"
            onClick={() => showModalForm(id)}
            type="text"
          >
            <FontAwesomeIcon icon="edit" />
          </Button>
        </>
      </div>
    );
  };

  const columns: ColumnsType<any> = [
    {
      dataIndex: "id",
      key: "id",
      responsive: ["xs"],
      render: (value, record) => {
        return (
          <Descriptions bordered>
            <Descriptions.Item label="Name:">{record?.name}</Descriptions.Item>

            <Divider />
            <Descriptions.Item label="Actions:">
              {record?.actions}
            </Descriptions.Item>
          </Descriptions>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      responsive: ["sm"],
    },

    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      responsive: ["sm"],
    },
  ];

  const data = groups?.map((el: any) => {
    const { id, name } = el;
    return {
      key: id,
      name: name,
      actions: actionsMarkup(id),
    };
  });

  const onFinish = (data: any) => {
    if (!data.id) {
      dispatch(saveGroup(data));
    } else {
      dispatch(updateGroup(data, data.id));
    }

    handleCancel();
  };

  const handleCancel = () => {
    form.resetFields();
    dispatch(cleanGroup());
    setVisibleForm(false);
  };

  return (
    <Card title="Groups">
      <div style={{ textAlign: "end" }}>
        <Button
          onClick={() => {
            setVisibleForm(true);
          }}
          type="primary"
        >
          Add Group
        </Button>
        <br />
        <br />
      </div>
      <div className="table-responsive">
        <Table
          bordered
          loading={loaderGroups}
          columns={columns}
          dataSource={data}
          pagination={{
            defaultPageSize: 10,
            current: groups?.current_page,
            pageSize: groups?.per_page,
            hideOnSinglePage: true,
            total: groups?.total,
            showSizeChanger: false,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
            onChange: getData,
          }}
        />
      </div>

      <Modal
        title={<>Add New Group</>}
        open={visibleForm}
        footer={<></>}
        centered
        onCancel={handleCancel}
        width="500px"
      >
        <div style={{ textAlign: "center" }}>
          <br />
          <Image
            preview={false}
            style={{ width: "100%" }}
            src={require("../../static/img/footer-logo.png")}
            alt="..."
          />
          <br />
          <br />
        </div>

        <GroupsForm form={form} onFinish={onFinish} onCancel={handleCancel} />
      </Modal>
    </Card>
  );
};

export default Groups;
