import { actions } from "./actions";
const {
  ACTION_BEGIN,
  ERROR,
  SUCCESS_CALCULATIONS,
  SUCCESS_CALCULATION,
  CLEAN_CALCULATION,

  SUCCESS_SIDES,
  SUCCESS_SIDE,
  CLEAN_SIDE,
} = actions;

const initialState = {
  loadingCalculations: false,
  loaderSides: false,

  calculations: {},
  calculation: {},

  sides: {},
  side: {},
};

export default function responseReducer(state = initialState, action: any) {
  const { type, data, err } = action;

  switch (type) {
    case ACTION_BEGIN:
      const stateClone = { ...state, ...data };
      return {
        ...stateClone,
      };

    case SUCCESS_CALCULATIONS:
      return {
        ...state,
        calculations: data,
      };

    case SUCCESS_CALCULATION:
      return {
        ...state,
        calculation: data,
      };

    case CLEAN_CALCULATION:
      return {
        ...state,
        calculation: {},
      };

    case SUCCESS_SIDES:
      return {
        ...state,
        sides: data,
      };

    case SUCCESS_SIDE:
      return {
        ...state,
        side: data,
      };

    case CLEAN_SIDE:
      return {
        ...state,
        side: {},
      };

    case ERROR:
      return {
        ...state,
        loadingSides: false,
        loaderCalculations: false,
        error: err,
      };
    default:
      return state;
  }
}
