import { Button, Card, Col, Form, Input, Row } from "antd";
import { useEffect } from "react";
import { useAuthUser } from "react-auth-kit";
import PhoneInput from "react-phone-input-2";
import { updateProfile } from "../../redux/user/actions";
import { useAppDispatch } from "../../redux/hooks";

/* eslint-disable react-hooks/exhaustive-deps */
const Profile = () => {
  const [form] = Form.useForm();
  const authUser = useAuthUser();
  const user = authUser();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const data = {
      email: user?.email,
      first_name: user?.user_detail?.first_name,
      last_name: user?.user_detail?.last_name,
      phone_number: user?.user_detail?.phone_number,
    };
    form.setFieldsValue(data);
  }, []);

  const onFinish = (data: any) => {
    dispatch(updateProfile(data));
  };

  return (
    <Card title="My Profile">
      <Form
        className="form-allunox"
        layout="vertical"
        name="users"
        form={form}
        onFinish={onFinish}
      >
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="first_name"
              label="First name"
              rules={[{ required: true, message: "Filed is required" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="last_name"
              label="Last name"
              rules={[{ required: true, message: "Filed is required" }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          name="phone_number"
          label="Phone"
          rules={[{ required: true, message: "Filed is required", min: 11 }]}
        >
          <PhoneInput
            inputClass="ant-input"
            enableAreaCodes={true}
            country={"ca"}
            onlyCountries={["ca", "us", "mx"]}
          />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={[
            { type: "email", message: "Please enter a correct email" },
            { required: true, message: "Filed is required" },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item style={{ textAlign: "end", marginBottom: 0, marginTop: 10 }}>
          <Button htmlType="submit" type="primary">
            {form.getFieldValue("id") ? "Update" : "Save"}
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default Profile;
