import api from "../../api";

export const actions = {
  SUCCESS_GROUP: "SUCCESS_GROUP",
  SUCCESS_GROUPS: "SUCCESS_GROUPS",
  SUCCESS_ROLES: "SUCCESS_ROLES",
  CLEAN_GROUP: "CLEAN_GROUP",
  ACTION_BEGIN: "ACTION_BEGIN",
  ERROR: "ERROR",
};

const path = "/groups";

const begin = (data: any) => {
  return (dispatch: any) => {
    dispatch({
      type: actions.ACTION_BEGIN,
      data,
    });
  };
};

const error = (err: any) => {
  return { type: actions.ERROR, err };
};

const getGroups = (page?: number) => {
  return async (dispatch: any) => {
    try {
      dispatch(begin({ loaderGroups: true }));

      const response = await api.get(`/groups${page ? "?page=" + page : ""}`);
      if (response.status >= 200 && response.status <= 201) {
        dispatch(begin({ loaderGroups: false }));
        const { data } = response;

        dispatch({
          type: actions.SUCCESS_GROUPS,
          data: data.data,
        });
        return;
      }

      dispatch(error(response?.data?.message));
      return response?.data?.message;
    } catch (err) {
      dispatch(error(err));
      return err;
    }
  };
};

const saveGroup = (data: any) => {
  return async (dispatch: any) => {
    try {
      const response = await api.post(`groups`, data);
      if (response.status >= 200 && response.status <= 201) {
        dispatch(getGroups());
        return;
      }

      dispatch(error(response?.data?.message));
      return response?.data?.message;
    } catch (err) {
      dispatch(error(err));
      return err;
    }
  };
};

const updateGroup = (data: any, id: number) => {
  return async (dispatch: any) => {
    try {
      const response = await api.put(`${path}/${id}`, data);
      if (response.status >= 200 && response.status <= 201) {
        dispatch(getGroups());
        return;
      }

      dispatch(error(response?.data?.message));
      return response?.data?.message;
    } catch (err) {
      dispatch(error(err));
      return err;
    }
  };
};

const deleteGroup = (id: number) => {
  return async (dispatch: any) => {
    try {
      const response = await api.delete(`${path}/${id}`);
      if (response.status >= 200 && response.status <= 201) {
        dispatch(getGroups());
        return;
      }

      dispatch(error(response?.data?.message));
      return response?.data?.message;
    } catch (err) {
      dispatch(error(err));
      return err;
    }
  };
};

const getGroupById = (id: number) => {
  return async (dispatch: any) => {
    try {
      const response = await api.get(`${path}/${id}`);
      if (response.status >= 200 && response.status <= 201) {
        const { data } = response;

        dispatch({
          type: actions.SUCCESS_GROUP,
          data: data.data,
        });
        return;
      }

      dispatch(error(response?.data?.message));
      return response?.data?.message;
    } catch (err) {
      dispatch(error(err));
      return err;
    }
  };
};

const cleanGroup = () => {
  return async (dispatch: any) => {
    dispatch({ type: actions.CLEAN_GROUP });
  };
};

export {
  begin,
  getGroups,
  saveGroup,
  updateGroup,
  getGroupById,
  deleteGroup,
  cleanGroup,
};
