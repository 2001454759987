import { Button, Col, Form, Input, Row, Select } from "antd";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../redux/hooks";
import { getCalculationsAll } from "../../redux/sides/actions";

/* eslint-disable react-hooks/exhaustive-deps */
const SidesForm = ({ form, onCancel, onFinish }: any) => {
  const dispatch = useAppDispatch();
  const id = Form.useWatch("id", form);
  const [calculations, setCalculations] = useState<any>([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const data = await dispatch(getCalculationsAll());
    if (data) setCalculations(data);
  };

  return (
    <Form
      className="form-allunox"
      layout="vertical"
      name="sides"
      form={form}
      onFinish={onFinish}
    >
      <Row gutter={24}>
        <Col span={24}>
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: "This field is required" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Calculation"
            name="calculations_id"
            rules={[{ required: true, message: "This field is required" }]}
          >
            <Select
              options={calculations?.map((calculation: any) => {
                return { value: calculation?.id, label: calculation?.name };
              })}
              allowClear
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item style={{ textAlign: "end", marginBottom: 0, marginTop: 10 }}>
        <Form.Item name="id" hidden>
          <Input />
        </Form.Item>

        <Button
          onClick={onCancel}
          style={{ marginRight: 10 }}
          htmlType="button"
          type="default"
        >
          Cancel
        </Button>

        <Button htmlType="submit" type="primary">
          {id ? "Update" : "Save"} Sides
        </Button>
      </Form.Item>
    </Form>
  );
};

export default SidesForm;
