import { Card, Image, Spin } from "antd";
import { Outlet } from "react-router-dom";
import { useAppSelector } from "../redux/hooks";

const WithAuthLayout = () => {
  const loading = useAppSelector((state) => state.authReducer.loading);

  return (
    <Spin spinning={loading}>
      <div className="auth">
        <Card className="container">
          <div className="inside">
            <br />
            <Image
              preview={false}
              src={require("../static/img/logo-white.png")}
            />

            <Outlet />
          </div>
        </Card>
      </div>
    </Spin>
  );
};

export default WithAuthLayout;
