import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Card,
  Descriptions,
  Divider,
  Form,
  Image,
  Modal,
  Popconfirm,
  Table,
} from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  getRoles,
  getUsers,
  saveUser,
  updateUser,
  getUserById,
  deleteUser,
  cleanUser,
} from "../../redux/users/actions";
import UsersForm from "./usersForm";
import { getGroups } from "../../redux/groups/actions";

/* eslint-disable react-hooks/exhaustive-deps */
const Users = () => {
  const [visibleForm, setVisibleForm] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const users = useAppSelector((state) => state.usersReducer.users);
  const user = useAppSelector((state) => state.usersReducer.user);
  const loaderUsers = useAppSelector((state) => state.usersReducer.loaderUsers);
  const groups = useAppSelector((state) => state.groupsReducer.groups);
  useEffect(() => {
    dispatch(getRoles());
    dispatch(getGroups());
    getData();
  }, []);

  useEffect(() => {
    if (user.id) {
      const data: any = {
        id: user.id,
        email: user.email,
        role: user.role,
        first_name: user.user_detail?.first_name,
        last_name: user.user_detail?.last_name,
        phone_number: user?.user_detail?.phone_number || "",
        group_id: user.group_id,
      };

      form.setFieldsValue(data);
    }
  }, [user]);

  const getData = (page = 1) => {
    dispatch(getUsers(page));
  };

  const showModalForm = (id: number) => {
    if (id) {
      dispatch(getUserById(id));
    }

    setVisibleForm(true);
  };

  const actionsMarkup = (id: number) => {
    return (
      <div className="table-actions">
        <>
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => dispatch(deleteUser(id))}
          >
            <Button type="text">
              <FontAwesomeIcon icon="trash-alt" />
            </Button>
          </Popconfirm>

          <Button
            className="color-primary"
            onClick={() => showModalForm(id)}
            type="text"
          >
            <FontAwesomeIcon icon="edit" />
          </Button>
        </>
      </div>
    );
  };

  const colums: ColumnsType<any> = [
    {
      dataIndex: "user",
      key: "use",
      responsive: ["xs"],
      render: (value, record) => {
        return (
          <Descriptions bordered>
            <Descriptions.Item label="Full Name:">
              {record?.name}
            </Descriptions.Item>
            <Descriptions.Item label="Phone:">
              {record?.phone_number}
            </Descriptions.Item>
            <Descriptions.Item label="Email:">
              {record?.email}
            </Descriptions.Item>
            <Descriptions.Item label="Role:">{record?.role}</Descriptions.Item>
            <Descriptions.Item label="Group:">
              {record?.group}
            </Descriptions.Item>

            <Divider />
            <Descriptions.Item label="Actions:">
              {record?.actions}
            </Descriptions.Item>
          </Descriptions>
        );
      },
    },
    {
      title: "Full Name",
      dataIndex: "name",
      key: "name",
      responsive: ["sm"],
    },
    {
      title: "Phone",
      dataIndex: "phone_number",
      key: "phone_number",
      responsive: ["sm"],
    },

    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      responsive: ["sm"],
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      responsive: ["md"],
    },
    {
      title: "Group",
      dataIndex: "group",
      key: "group",
      responsive: ["md"],
    },

    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      responsive: ["sm"],
    },
  ];

  const data = users?.data?.map((el: any) => {
    const { id, email, role, user_detail } = el;
    return {
      key: id,
      email,
      role,
      name: user_detail?.first_name + " " + user_detail?.last_name,
      phone_number: user_detail?.phone_number || "--",
      group: groups?.find((group: any) => group.id === el.group_id)?.name,
      actions: actionsMarkup(id),
    };
  });

  const onFinish = (data: any) => {
    if (!data.id) {
      dispatch(saveUser(data));
    } else {
      dispatch(updateUser(data, data.id));
    }

    handleCancel();
  };

  const handleCancel = () => {
    form.resetFields();
    dispatch(cleanUser());
    setVisibleForm(false);
  };

  return (
    <Card title="Users">
      <div style={{ textAlign: "end" }}>
        <Button
          onClick={() => {
            setVisibleForm(true);
          }}
          type="primary"
        >
          Add User
        </Button>
        <br />
        <br />
      </div>
      <div className="table-responsive">
        <Table
          bordered
          loading={loaderUsers}
          columns={colums}
          dataSource={data}
          pagination={{
            defaultPageSize: 10,
            current: users?.current_page,
            pageSize: users?.per_page,
            hideOnSinglePage: true,
            total: users?.total,
            showSizeChanger: false,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
            onChange: getData,
          }}
        />
      </div>

      <Modal
        title={<>Add New User</>}
        open={visibleForm}
        footer={<></>}
        centered
        onCancel={handleCancel}
        width="500px"
      >
        <div style={{ textAlign: "center" }}>
          <br />
          <Image
            preview={false}
            style={{ width: "100%" }}
            src={require("../../static/img/footer-logo.png")}
            alt="..."
          />
          <br />
          <br />
        </div>

        <UsersForm form={form} onFinish={onFinish} onCancel={handleCancel} />
      </Modal>
    </Card>
  );
};

export default Users;
