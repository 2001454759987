import api from "../../api";

export const actions = {
  SUCCESS_USER: "SUCCESS_USER",
  SUCCESS_USERS: "SUCCESS_USERS",
  SUCCESS_ROLES: "SUCCESS_ROLES",
  CLEAN_USER: "CLEAN_USER",
  ACTION_BEGIN: "ACTION_BEGIN",
  ERROR: "ERROR",
};

const path = "/users";

const begin = (data: any) => {
  return (dispatch: any) => {
    dispatch({
      type: actions.ACTION_BEGIN,
      data,
    });
  };
};

const error = (err: any) => {
  return { type: actions.ERROR, err };
};

const getRoles = (page?: number) => {
  return async (dispatch: any) => {
    try {
      const response = await api.get(
        `${path}/roles${page ? "?page=" + page : ""}`
      );
      if (response.status >= 200 && response.status <= 201) {
        const { data } = response;

        dispatch({
          type: actions.SUCCESS_ROLES,
          data: data.data,
        });

        return;
      }

      dispatch(error(response?.data?.message));
      return response?.data?.message;
    } catch (err) {
      dispatch(error(err));
      return err;
    }
  };
};

const getUsers = (page?: number, group?: number) => {
  return async (dispatch: any) => {
    try {
      dispatch(begin({ loaderUsers: true }));

      const response = await api.get(
        `${path}`,

        { params: { page, group_id: group } }
      );
      if (response.status >= 200 && response.status <= 201) {
        dispatch(begin({ loaderUsers: false }));
        const { data } = response;

        dispatch({
          type: actions.SUCCESS_USERS,
          data: data.data,
        });
        return;
      }

      dispatch(error(response?.data?.message));
      return response?.data?.message;
    } catch (err) {
      dispatch(error(err));
      return err;
    }
  };
};

const saveUser = (data: any) => {
  return async (dispatch: any) => {
    try {
      const response = await api.post(`${path}`, data);
      if (response.status >= 200 && response.status <= 201) {
        dispatch(getUsers());
        return;
      }

      dispatch(error(response?.data?.message));
      return response?.data?.message;
    } catch (err) {
      dispatch(error(err));
      return err;
    }
  };
};

const updateUser = (data: any, id: number) => {
  return async (dispatch: any) => {
    try {
      const response = await api.put(`${path}/${id}`, data);
      if (response.status >= 200 && response.status <= 201) {
        dispatch(getUsers());
        return;
      }

      dispatch(error(response?.data?.message));
      return response?.data?.message;
    } catch (err) {
      dispatch(error(err));
      return err;
    }
  };
};

const deleteUser = (id: number) => {
  return async (dispatch: any) => {
    try {
      const response = await api.delete(`${path}/${id}`);
      if (response.status >= 200 && response.status <= 201) {
        dispatch(getUsers());
        return;
      }

      dispatch(error(response?.data?.message));
      return response?.data?.message;
    } catch (err) {
      dispatch(error(err));
      return err;
    }
  };
};

const getUserById = (id: number) => {
  return async (dispatch: any) => {
    try {
      const response = await api.get(`${path}/${id}`);
      if (response.status >= 200 && response.status <= 201) {
        const { data } = response;

        dispatch({
          type: actions.SUCCESS_USER,
          data: data.data,
        });
        return;
      }

      dispatch(error(response?.data?.message));
      return response?.data?.message;
    } catch (err) {
      dispatch(error(err));
      return err;
    }
  };
};

const cleanUser = () => {
  return async (dispatch: any) => {
    dispatch({ type: actions.CLEAN_USER });
  };
};

export {
  begin,
  getRoles,
  getUsers,
  saveUser,
  updateUser,
  getUserById,
  deleteUser,
  cleanUser,
};
