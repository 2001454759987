import { Button, Col, Form, Input, Row } from "antd";

const CalculationsForm = ({ form, onCancel, onFinish }: any) => {
  const id = Form.useWatch("id", form);

  return (
    <Form
      className="form-allunox"
      layout="vertical"
      name="calculations"
      form={form}
      onFinish={onFinish}
    >
      <Row gutter={24}>
        <Col span={24}>
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: "This field is required" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Description"
            name="description"
            rules={[{ required: true, message: "This field is required" }]}
          >
            <Input.TextArea />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item style={{ textAlign: "end", marginBottom: 0, marginTop: 10 }}>
        <Form.Item name="id" hidden>
          <Input />
        </Form.Item>

        <Button
          onClick={onCancel}
          style={{ marginRight: 10 }}
          htmlType="button"
          type="default"
        >
          Cancel
        </Button>

        <Button htmlType="submit" type="primary">
          {id ? "Update" : "Save"} Calculations
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CalculationsForm;
