import React, { useEffect, useState } from "react";
import { Form, Input, Button, message, List } from "antd";
import { createBalconyNote } from "../../../redux/projects/actions";
import { useAppDispatch } from "../../../redux/hooks";
import moment from "moment";

const BalconyNotes = ({
  notes,
  balconyId,
  onNoteCreated,
}: {
  notes: any;
  balconyId: number;
  onNoteCreated: () => void;
}) => {
  const [messageApi, contextHolder] = message.useMessage();

  const dispatch = useAppDispatch();

  const createNote = async (values: any) => {
    const response = (await dispatch(
      createBalconyNote(values, balconyId)
    )) as any;
    if (response.success) {
      messageApi.open({
        type: "success",
        content: "Note created successfully",
        duration: 3,
      });
      onNoteCreated();
    }
  };
  return (
    <div>
      {contextHolder}
      <Form layout="vertical" onFinish={createNote}>
        <Form.Item label="Description" name="comment">
          <Input.TextArea />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 16, span: 16 }}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>

      <List
        style={{ height: "300px", overflow: "auto" }}
        itemLayout="horizontal"
        dataSource={notes}
        renderItem={(item: any, index) => (
          <List.Item>
            <List.Item.Meta
              title={
                <small>
                  {moment(item.created_at).format("MMMM Do, YYYY")}{" "}
                  {item.user.user_detail.first_name}{" "}
                  {item.user.user_detail.last_name}
                </small>
              }
              description={item.comment}
            />
          </List.Item>
        )}
      />
    </div>
  );
};
export default BalconyNotes;
