import { configureStore } from "@reduxjs/toolkit";
import { logger } from "redux-logger";
import rootReducer from "./rootReducers";
import thunk from "redux-thunk";

const middleware: any = [thunk];
if (process.env.NODE_ENV !== "production") {
  middleware.push(logger);
}

export const store = configureStore({ reducer: rootReducer, middleware });

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
