import { actions } from "./actions";
const {
  ACTION_BEGIN,
  ERROR,
  SUCCESS_DEFAULT_MEASURES,
  CLEAN_DEFAULT_MEASURES,
} = actions;

const initialState = {
  loaderDefaultMeasures: false,
  defaultMeasures: [],
};

export default function responseReducer(state = initialState, action: any) {
  const { type, data, err } = action;

  switch (type) {
    case ACTION_BEGIN:
      const stateClone = { ...state, ...data };
      return {
        ...stateClone,
      };
    case SUCCESS_DEFAULT_MEASURES:
      return {
        ...state,
        defaultMeasures: data,
      };

    case CLEAN_DEFAULT_MEASURES:
      return {
        ...state,
        defaultMeasures: {},
      };
    case ERROR:
      return {
        ...state,
        loaderDefaultMeasures: false,
        error: err,
      };
    default:
      return state;
  }
}
