import api from "../../api";

export const actions = {
  SUCCESS_CALCULATIONS: "SUCCESS_CALCULATIONS",
  SUCCESS_CALCULATION: "SUCCESS_CALCULATION",
  CLEAN_CALCULATION: "CLEAN_CALCULATION",

  SUCCESS_SIDES: "SUCCESS_SIDES",
  SUCCESS_SIDE: "SUCCESS_SIDE",
  CLEAN_SIDE: "CLEAN_SIDE",

  ACTION_BEGIN: "ACTION_BEGIN",
  ERROR: "ERROR",
};

const begin = (data: any) => {
  return (dispatch: any) => {
    dispatch({
      type: actions.ACTION_BEGIN,
      data,
    });
  };
};

const error = (err: any) => {
  return { type: actions.ERROR, err };
};

const pathCalculations = "/calculations";
const getCalculationsAll = () => {
  return async (dispatch: any) => {
    try {
      dispatch(begin({ loaderCalculations: true }));
      const response = await api.get(`${pathCalculations}-all`);
      dispatch(begin({ loaderCalculations: false }));

      if (response.status >= 200 && response.status <= 201) {
        const { data } = response;

        return data.data;
      }

      dispatch(error(response?.data?.message));
      return false;
    } catch (err) {
      dispatch(error(err));
      return false;
    }
  };
};

const getCalculations = (page?: number) => {
  return async (dispatch: any) => {
    try {
      dispatch(begin({ loaderCalculations: true }));
      const response = await api.get(
        `${pathCalculations}${page ? "?page=" + page : ""}`
      );
      dispatch(begin({ loaderCalculations: false }));

      if (response.status >= 200 && response.status <= 201) {
        const { data } = response;

        dispatch({
          type: actions.SUCCESS_CALCULATIONS,
          data: data.data,
        });
        return;
      }

      dispatch(error(response?.data?.message));
      return response?.data?.message;
    } catch (err) {
      dispatch(error(err));
      return err;
    }
  };
};

const saveCalculation = (data: { name: string; description: string }) => {
  return async (dispatch: any) => {
    try {
      const response = await api.post(`${pathCalculations}`, data);
      if (response.status >= 200 && response.status <= 201) {
        dispatch(getCalculations());
        return;
      }

      dispatch(error(response?.data?.message));
      return response?.data?.message;
    } catch (err) {
      dispatch(error(err));
      return err;
    }
  };
};

const updateCalculation = (
  data: { name?: string; description?: string; status?: number },
  id: number
) => {
  return async (dispatch: any) => {
    try {
      const response = await api.put(`${pathCalculations}/${id}`, data);
      if (response.status >= 200 && response.status <= 201) {
        dispatch(getCalculations());
        return;
      }

      dispatch(error(response?.data?.message));
      return response?.data?.message;
    } catch (err) {
      dispatch(error(err));
      return err;
    }
  };
};

const getCalculationById = (id: number) => {
  return async (dispatch: any) => {
    try {
      const response = await api.get(`${pathCalculations}/${id}`);
      if (response.status >= 200 && response.status <= 201) {
        const { data } = response;

        dispatch({
          type: actions.SUCCESS_CALCULATION,
          data: data.data,
        });
        return;
      }

      dispatch(error(response?.data?.message));
      return response?.data?.message;
    } catch (err) {
      dispatch(error(err));
      return err;
    }
  };
};

const cleanCalculation = () => {
  return async (dispatch: any) => {
    dispatch({ type: actions.CLEAN_CALCULATION });
  };
};

const pathSides = "/sides";
const getSidesAll = () => {
  return async (dispatch: any) => {
    try {
      dispatch(begin({ loaderSides: true }));
      const response = await api.get(`${pathSides}-all`);
      dispatch(begin({ loaderSides: false }));

      if (response.status >= 200 && response.status <= 201) {
        const { data } = response;

        return data.data;
      }

      dispatch(error(response?.data?.message));
      return false;
    } catch (err) {
      dispatch(error(err));
      return false;
    }
  };
};

const getSides = (page?: number) => {
  return async (dispatch: any) => {
    try {
      dispatch(begin({ loaderSides: true }));
      const response = await api.get(
        `${pathSides}${page ? "?page=" + page : ""}`
      );
      dispatch(begin({ loaderSides: false }));

      if (response.status >= 200 && response.status <= 201) {
        const { data } = response;

        dispatch({
          type: actions.SUCCESS_SIDES,
          data: data.data,
        });
        return;
      }

      dispatch(error(response?.data?.message));
      return response?.data?.message;
    } catch (err) {
      dispatch(error(err));
      return err;
    }
  };
};

const saveSide = (data: { name: string; calculations_id: number }) => {
  return async (dispatch: any) => {
    try {
      const response = await api.post(`${pathSides}`, data);
      if (response.status >= 200 && response.status <= 201) {
        dispatch(getSides());
        return;
      }

      dispatch(error(response?.data?.message));
      return response?.data?.message;
    } catch (err) {
      dispatch(error(err));
      return err;
    }
  };
};

const updateSide = (
  data: { name?: string; calculations_id?: number; status?: number },
  id: number
) => {
  return async (dispatch: any) => {
    try {
      const response = await api.put(`${pathSides}/${id}`, data);
      if (response.status >= 200 && response.status <= 201) {
        dispatch(getSides());
        return;
      }

      dispatch(error(response?.data?.message));
      return response?.data?.message;
    } catch (err) {
      dispatch(error(err));
      return err;
    }
  };
};

const getSideById = (id: number) => {
  return async (dispatch: any) => {
    try {
      const response = await api.get(`${pathSides}/${id}`);
      if (response.status >= 200 && response.status <= 201) {
        const { data } = response;

        dispatch({
          type: actions.SUCCESS_SIDE,
          data: data.data,
        });
        return;
      }

      dispatch(error(response?.data?.message));
      return response?.data?.message;
    } catch (err) {
      dispatch(error(err));
      return err;
    }
  };
};

const cleanSide = () => {
  return async (dispatch: any) => {
    dispatch({ type: actions.CLEAN_SIDE });
  };
};

export {
  begin,
  getCalculationsAll,
  getCalculations,
  saveCalculation,
  updateCalculation,
  getCalculationById,
  cleanCalculation,
  getSidesAll,
  getSides,
  saveSide,
  updateSide,
  getSideById,
  cleanSide,
};
