import { Select } from "antd";
import { useEffect } from "react";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { getProjects } from "../../../redux/projects/actions";
const { Option } = Select;

interface GroupSelectProps {}
const GroupSelect = ({}: GroupSelectProps) => {
  const dispatch = useAppDispatch();
  const groups = useAppSelector((state) => state.groupsReducer.groups);
  /* add all groups options to group */

  useEffect(() => {}, []);
  const handleChange = (value: any) => {
    dispatch(
      getProjects(1, {
        group_id: value,
      })
    );
  };

  return (
    <>
      <Select
        onChange={handleChange}
        defaultValue={0}
        style={{ width: "100%" }}
      >
        <Select.Option value={0}>All groups</Select.Option>
        {groups.map((group: any) => (
          <Select.Option key={group.id} value={group.id}>
            {group.name}
          </Select.Option>
        ))}
      </Select>
    </>
  );
};

export default GroupSelect;
