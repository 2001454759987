import { useAuthUser, useIsAuthenticated } from "react-auth-kit";
import { Navigate } from "react-router-dom";

const RolesAuthRoute = ({ loginPath, rolesRoute, children }: any) => {
  const isAuthenticated = useIsAuthenticated();
  const authUser = useAuthUser();
  const user = authUser();
  const auth = isAuthenticated();

  const canAccess =
    !rolesRoute ||
    [user?.role].some((role: string) => rolesRoute.includes(role));
  if (!auth) return <Navigate to={loginPath} />;

  return auth && canAccess ? children : <Navigate to={"/403"} />;
};

export default RolesAuthRoute;
