import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Form, Image, Modal, Popconfirm, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  getCalculations,
  getCalculationById,
  updateCalculation,
  saveCalculation,
  cleanCalculation,
} from "../../redux/sides/actions";
import CalculationsForm from "./calculationsForm";

/* eslint-disable react-hooks/exhaustive-deps */
const Calculations = () => {
  const [visibleForm, setVisibleForm] = useState(false);

  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const calculation = useAppSelector((state) => state.sidesReducer.calculation);
  const calculations = useAppSelector(
    (state) => state.sidesReducer.calculations
  );
  const loaderCalculations = useAppSelector(
    (state) => state.sidesReducer.loaderCalculations
  );

  useEffect(() => {
    getData(calculations?.current_page || 1);
  }, []);

  useEffect(() => {
    if (calculation.id) {
      form.setFieldsValue(calculation);
    }
  }, [calculation]);

  const getData = (page = 1) => {
    dispatch(getCalculations(page));
  };

  const showModalForm = async (id: number) => {
    if (id) await dispatch(getCalculationById(id));

    setVisibleForm(true);
  };

  const actionsMarkup = (id: number) => {
    return (
      <div className="table-actions">
        <>
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => dispatch(updateCalculation({ status: 2 }, id))}
          >
            <Button type="text">
              <FontAwesomeIcon icon="trash-alt" />
            </Button>
          </Popconfirm>
          <Button
            className="color-primary"
            onClick={() => showModalForm(id)}
            type="text"
          >
            <FontAwesomeIcon icon="edit" />
          </Button>
        </>
      </div>
    );
  };

  const colums: ColumnsType<any> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },

    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
    },
  ];

  const data = calculations?.data?.map((el: any) => {
    const { id, name, description } = el;
    return {
      key: id,
      id,
      name,
      description,
      actions: actionsMarkup(id),
    };
  });

  const onFinish = (data: any) => {
    if (calculations?.id) {
      dispatch(updateCalculation(data, calculations?.id));
    } else {
      dispatch(saveCalculation(data));
    }

    handleCancel();
  };

  const handleCancel = () => {
    dispatch(cleanCalculation());
    form.resetFields();
    setVisibleForm(false);
  };

  return (
    <Card title="Calculations">
      <div style={{ textAlign: "end" }}>
        <Button
          onClick={() => {
            setVisibleForm(true);
          }}
          type="primary"
        >
          Add Calculation
        </Button>
        <br />
        <br />
      </div>
      <div className="table-responsive">
        <Table
          bordered
          loading={loaderCalculations}
          columns={colums}
          dataSource={data}
          pagination={{
            current: calculations?.current_page,
            pageSize: calculations?.per_page || 0,
            hideOnSinglePage: true,
            total: calculations?.total,
            showSizeChanger: false,
            showTotal: (total: number, range: any[]) =>
              `${range[0]}-${range[1]} of ${total} items`,
            onChange: getData,
          }}
        />
      </div>

      <Modal
        title={<>Update Sides</>}
        open={visibleForm}
        footer={<></>}
        onCancel={handleCancel}
      >
        <div style={{ textAlign: "center" }}>
          <br />
          <Image
            preview={false}
            style={{ width: "100%" }}
            src={require("../../static/img/footer-logo.png")}
            alt="..."
          />
          <br />
          <br />
        </div>

        <CalculationsForm
          form={form}
          onFinish={onFinish}
          onCancel={handleCancel}
        />
      </Modal>
    </Card>
  );
};

export default Calculations;
