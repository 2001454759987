import { actions } from "./actions";
const {
  ACTION_BEGIN,
  ERROR,
  SUCCESS_PROJECTS,
  SUCCESS_PROJECT,
  CLEAN_PROJECT,
  SUCCESS_BALCONY_NOTE,
} = actions;

const initialState = {
  loading: false,
  loaderProjects: false,
  loaderFloors: false,
  loaderBalconies: false,
  projects: {},
  project: {},
  balconyNote: {},
};

export default function responseReducer(state = initialState, action: any) {
  const { type, data, err } = action;

  switch (type) {
    case ACTION_BEGIN:
      const stateClone = { ...state, ...data };
      return {
        ...stateClone,
      };

    case SUCCESS_PROJECTS:
      return {
        ...state,
        projects: data,
      };
    case SUCCESS_PROJECT:
      return {
        ...state,
        project: data,
      };

    case CLEAN_PROJECT:
      return {
        ...state,
        project: {},
      };
    case SUCCESS_BALCONY_NOTE:
      return {
        ...state,
        balconyNote: {},
      };
    case ERROR:
      return {
        ...state,
        loading: false,
        loaderProjects: false,
        loaderFloors: false,
        loaderBalconies: false,
        error: err,
      };
    default:
      return state;
  }
}
