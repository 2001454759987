/* eslint-disable no-useless-escape */
export const validateEmail = (value: any) => {
  const validRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  return value.match(validRegex);
};

export const validateEmailPhone = (value: any) => {
  const validRegex =
    /^([a-zA-Z0-9_\.\-+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})|([+0-9]{10,})+$/;

  return value.match(validRegex);
};
