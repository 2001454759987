import {
  getBalconyPDF,
  overrideDefaultMeasurements,
  updateBalcony,
  updateBalconyType,
} from "../../../redux/projects/actions";

import BalconyTypeSelect from "./balconyTypeSelect";
import ProjectsForm from "../projectsForm";
import ProjectsFormData from "../projectsFormData";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { EditText } from "react-edit-text";
import {
  Avatar,
  Row,
  Col,
  Tooltip,
  Button,
  Drawer,
  Dropdown,
  Collapse,
  Tag,
  Form,
  Modal,
  Spin,
  Progress,
} from "antd";
import type { PopconfirmProps } from "antd";
import { message, Popconfirm } from "antd";

import OverrideMeasurements from "./overrideMeasurements";
import { useAuthUser } from "react-auth-kit";
import { ROLES } from "../../../utils/roles";
import BalconyNotes from "./balconyNotes";

const { Panel } = Collapse;

const BalconyRow = ({
  balcony,
  onCloneBalcony,
  onEditBalcony,
  onDeleteBalcony,
  onBalconyMeasurements,
  style,
}: {
  balcony: any;
  onCloneBalcony: any;
  onEditBalcony: any;
  onDeleteBalcony: any;
  onBalconyMeasurements: any;
  style?: React.CSSProperties;
}) => {
  const [visibleForm, setVisibleForm] = useState(false);
  const [form] = Form.useForm();
  const [formData] = Form.useForm();
  const project = useAppSelector((state) => state.projectsReducer.project);

  const [layers, setLayers] = useState<any>({
    1: { active: true, label: "Glass", value: "glass" },
    2: { active: true, label: "Center to Center ", value: "center_to_center" },
    3: {
      active: true,
      label: "Inputs",
      value: "inputs",
    },
    4: { active: true, label: "Railing /wall Measurement", value: "railing" },
  });
  const [loaderPlan, setLoaderVisiblePlan] = useState(false);
  const [pdfInfo, setPdfInfo] = useState<any>([]);
  const [visiblePlan, setVisiblePlan] = useState(false);
  const [visibleNotes, setVisibleNotes] = useState(false);
  const [loadProcess, setLoadProcess] = useState({ total: 0, process: 0 });
  const [balconyForNotes, setBalconyForNotes] = useState<any>({});
  const authUser = useAuthUser();

  const user = authUser();
  const dispatch = useAppDispatch();
  const handleBalconyTypeChange = (value: any) => {
    /*     dispatch(updateBalconyType({ type: value }, balcony.id));
     */
  };
  const cloneBalcony = (balcony: any) => {
    onCloneBalcony(balcony);
  };
  const deleteBalcony = (balcony: any) => {
    onDeleteBalcony(balcony);
  };
  const editBalcony = (balcony: any) => {
    onEditBalcony(balcony);
  };
  const balconyMeasurements = (balcony: any) => {
    onBalconyMeasurements(balcony);
  };
  const overrideMeasurements = (balcony: any) => {
    console.log("overrideMeasurements", balcony);
  };

  const handleCancel = () => {
    setVisibleForm(false);
  };

  const onFinishData = (values: any) => {
    dispatch(overrideDefaultMeasurements(values, balcony.id));
    setVisibleForm(false);
  };

  const openPDFBalcony = async (balcony: any) => {
    setVisiblePlan(true);
    const layersSelected = formatLayers(layers);
    try {
      const balconyPdf: any = await dispatch(
        getBalconyPDF(balcony.id, {
          layers: layersSelected,
          version: project?.version,
        })
      );

      /* blob to pdf */
      const file = new Blob([balconyPdf], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      setPdfInfo(fileURL);
    } catch (error) {
      console.error("Failed to fetch PDF:", error);
      // Handle error, e.g., show an error message to the user
    }
  };
  const formatLayers = (layers: any) => {
    const layersArray = Object.keys(layers).map((layer) => {
      return layers[layer].active ? layers[layer].value : "";
    });
    return layersArray.filter((layer: any) => layer !== "");
  };
  const openNotes = (balcony: any) => {
    setVisibleNotes(true);
    setBalconyForNotes(balcony);
  };

  const cancel: PopconfirmProps["onCancel"] = (e) => {};

  return (
    <div style={style}>
      <Row>
        <Col xs={4} sm={4} md={4} lg={2} xl={2}>
          <Avatar shape="square" icon={<FontAwesomeIcon icon="grip-lines" />} />
        </Col>

        <Col xs={20} sm={20} md={20} lg={4} xl={4}>
          <div className="inline">
            <EditText
              placeholder="Balcony Name"
              defaultValue={balcony.full_name}
              onSave={(e) => {
                dispatch(updateBalcony({ name: e.value }, balcony.id));
              }}
            />
          </div>
        </Col>
        <Col xs={20} sm={20} md={20} lg={4} xl={4}>
          {/* if exist parent id */}
          <span>
            {balcony.measurements.parent_id ? (
              <span>
                <FontAwesomeIcon icon="level-up-alt" />
                {balcony.measurements.parent_id}
              </span>
            ) : (
              <></>
            )}
          </span>
          <span></span>
        </Col>
        <Col xs={24} sm={24} md={4} lg={4} xl={4}>
          {balcony?.measurements?.balcony_type_name}
        </Col>

        {project?.versions.length > 0 && (
          <>
            <Col xs={6} sm={6} md={1} lg={1} xl={1}>
              <Button
                onClick={() => openNotes(balcony)}
                type="text"
                icon={
                  <FontAwesomeIcon
                    style={{
                      color: balcony?.comments.length > 0 ? "yellow" : "black",
                    }}
                    icon={"sticky-note"}
                    size="lg"
                  />
                }
              />
            </Col>
            <Col xs={6} sm={6} md={1} lg={1} xl={1}>
              <Button
                onClick={() => balconyMeasurements(balcony)}
                type="text"
                icon={
                  <FontAwesomeIcon
                    style={{
                      color: "black",
                    }}
                    icon={"eye"}
                    size="lg"
                  />
                }
              />
            </Col>

            <Col xs={6} sm={6} md={1} lg={1} xl={1}>
              <Button
                onClick={() => openPDFBalcony(balcony)}
                type="text"
                icon={
                  <FontAwesomeIcon
                    style={{
                      color: "black",
                    }}
                    icon={"file-pdf"}
                    size="lg"
                  />
                }
              />
            </Col>
            <Col xs={6} sm={6} md={1} lg={1} xl={1}>
              <Tooltip title="Edit balcony">
                <Button
                  onClick={() => {
                    editBalcony(balcony);
                  }}
                  type="text"
                  icon={
                    <FontAwesomeIcon
                      style={{
                        color: balcony?.measurements?.input ? "green" : "black",
                      }}
                      icon={"edit"}
                      size="lg"
                    />
                  }
                />
              </Tooltip>
            </Col>
            <Col xs={6} sm={6} md={1} lg={1} xl={1}>
              <Tooltip title="Clone balcony">
                <Button
                  onClick={() => {
                    cloneBalcony(balcony);
                  }}
                  type="text"
                  icon={
                    <FontAwesomeIcon
                      style={{
                        color: "black",
                      }}
                      icon={"clone"}
                      size="lg"
                    />
                  }
                />
              </Tooltip>
            </Col>
            <Col xs={6} sm={6} md={1} lg={1} xl={1}>
              <Tooltip title="Override measurements">
                <Button
                  onClick={() => {
                    form.setFieldsValue({
                      id: project?.id,
                      project_id: project?.project_id,
                      engineering: project?.engineering,
                      address_line_1: project?.project_address.address_line_1,
                      address_line_2: project?.project_address.address_line_2,
                      province: project?.project_address.province,
                      city: project?.project_address.city,
                      postal_code: project?.project_address.postal_code,
                    });

                    formData.setFieldsValue(
                      balcony?.measurements?.overwrite ||
                        project.default_measures
                    );
                    setVisibleForm(true);
                  }}
                  type="text"
                  icon={
                    <FontAwesomeIcon
                      style={{
                        color: "black",
                      }}
                      icon={"wrench"}
                      size="lg"
                    />
                  }
                />
              </Tooltip>
            </Col>
          </>
        )}
        {user?.role === ROLES.ROLE_SUPER_ADMIN ||
        user?.role === ROLES.ROLE_ADMIN_USER ? (
          <Col xs={6} sm={6} md={1} lg={1} xl={1}>
            <Tooltip title="Delete balcony">
              <Popconfirm
                title="Delete?"
                description=""
                onConfirm={() => deleteBalcony(balcony)}
                onCancel={cancel}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  /*  onClick={() => {
                    deleteBalcony(balcony);
                  }} */
                  type="text"
                  icon={
                    <FontAwesomeIcon
                      style={{
                        color: "black",
                      }}
                      icon={"trash"}
                      size="lg"
                    />
                  }
                />
              </Popconfirm>
            </Tooltip>
          </Col>
        ) : (
          <></>
        )}
      </Row>

      <Drawer
        open={visibleForm}
        onClose={handleCancel}
        mask={false}
        width={400}
        destroyOnClose
        contentWrapperStyle={{ boxShadow: "1px 1px 1px 1px" }}
        bodyStyle={{ paddingLeft: "5px", paddingRight: "5px" }}
        title={
          <>
            Project ID:{" "}
            <Tag style={{ fontSize: 16 }}>{project?.project_id}</Tag>
          </>
        }
      >
        <Collapse
          className="custom-collapse"
          defaultActiveKey={[2]}
          ghost
          expandIconPosition="end"
        >
          <Panel className="custom-collapse" header="Data" key={2}>
            <OverrideMeasurements
              form={formData}
              onFinish={onFinishData}
              onCancel={handleCancel}
            />
          </Panel>
        </Collapse>
      </Drawer>

      <Modal
        title={"Balcony"}
        open={visiblePlan}
        centered
        destroyOnClose
        footer={<></>}
        onCancel={() => {
          setVisiblePlan(false);
          setLoaderVisiblePlan(false);
          setLoadProcess({ total: 0, process: 0 });
          setPdfInfo("");
        }}
        width="1200px"
      >
        <div style={{ textAlign: "center", marginTop: 20 }}>
          <Row>
            <Col sm={6}>
              <div style={{ marginBottom: 10, textAlign: "start" }}>
                {Object.keys(layers).map((layer, i) => {
                  return (
                    <div
                      key={i}
                      onClick={() => {
                        if (!loaderPlan) {
                          layers[layer].active = !layers[layer].active;

                          setLayers({ ...layers });
                          openPDFBalcony(balcony);
                        }
                      }}
                      style={{
                        margin: "5px 20px",
                        fontSize: 16,
                        cursor: "pointer",
                      }}
                    >
                      <FontAwesomeIcon
                        style={{ paddingRight: 5 }}
                        icon={layers[layer].active ? "square-check" : "square"}
                      />
                      {layers[layer].label}
                    </div>
                  );
                })}
              </div>
            </Col>
            <Col sm={18}>
              {loaderPlan && (
                <Progress
                  percent={parseFloat(
                    ((loadProcess.process * 100) / loadProcess.total).toFixed(2)
                  )}
                  status="active"
                />
              )}
              <Spin spinning={loaderPlan}>
                {visiblePlan && (
                  <iframe
                    style={{ minHeight: "900px", height: "100%" }}
                    title="frame"
                    src={pdfInfo ? pdfInfo : undefined}
                    height="100%"
                    width="100%"
                  />
                )}
              </Spin>
            </Col>
          </Row>
        </div>
      </Modal>

      <Modal
        open={visibleNotes}
        centered
        destroyOnClose
        footer={<></>}
        onCancel={() => {
          setVisibleNotes(false);
        }}
        width="400px"
      >
        <BalconyNotes
          notes={balconyForNotes?.comments || []}
          balconyId={balconyForNotes?.id}
          onNoteCreated={() => {
            setBalconyForNotes({ ...balcony });
          }}
        ></BalconyNotes>
      </Modal>
    </div>
  );
};

export default BalconyRow;
