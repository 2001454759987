import React from "react";
import { createBrowserRouter } from "react-router-dom";
import ErrorPage from "../pages/error-page";

import ErrorPage403 from "../pages/403";
import { auth } from "./auth";
import { admin } from "./admin";

const Root = React.lazy(() => import("../app"));

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [...auth, ...admin],
  },
  {
    path: "403",
    element: <ErrorPage403 />,
  },
]);
