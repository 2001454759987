import RolesAuthRoute from "../components/rolesAuthRoute";
import { ROLES } from "../utils/roles";

import WithAdminLayout from "../layouts/withAdminLayout";
import Profile from "../pages/user/profile";
import Users from "../pages/users/users";
import Projects from "../pages/projects/projects";
import ProjectsInfo from "../pages/projects/projectsInfo";
import Details from "../pages/projects/overview/details";
import InstallationProgress from "../pages/projects/overview/installationProgress";
import Measurements from "../pages/projects/overview/measurements";
import { Navigate, Outlet } from "react-router-dom";
import BalconyTypes from "../pages/balconyType/balconyTypes";
import DefaultMeasures from "../pages/defaultMeasures/defaultMeasures";
import Sides from "../pages/sides/sides";
import Calculations from "../pages/calculations/calculations";
import Groups from "../pages/groups/groups";

export const admin = [
  {
    path: "dashboard",
    element: <RolesAuthRoute loginPath="/" children={<WithAdminLayout />} />,
    children: [
      {
        index: true,
        element: <Navigate to={"projects"} />,
      },
      { path: "profile", element: <Profile /> },
      {
        path: "users",
        element: (
          <RolesAuthRoute
            rolesRoute={[ROLES.ROLE_SUPER_ADMIN, ROLES.ROLE_ADMIN_USER]}
            children={<Users />}
          />
        ),
      },
      {
        path: "balcony-types",
        element: (
          <RolesAuthRoute
            rolesRoute={[ROLES.ROLE_SUPER_ADMIN, ROLES.ROLE_ADMIN_USER]}
            children={<BalconyTypes />}
          />
        ),
      },
      {
        path: "default-measures",
        element: (
          <RolesAuthRoute
            rolesRoute={[ROLES.ROLE_SUPER_ADMIN, ROLES.ROLE_ADMIN_USER]}
            children={<DefaultMeasures />}
          />
        ),
      },
      {
        path: "sides",
        element: <RolesAuthRoute children={<Outlet />} />,
        children: [
          { path: "list", element: <Sides /> },
          { path: "calculations", element: <Calculations /> },
        ],
      },
      {
        path: "projects",
        element: (
          <RolesAuthRoute
            rolesRoute={[
              ROLES.ROLE_SUPER_ADMIN,
              ROLES.ROLE_ADMIN_USER,
              ROLES.ROLE_USER,
            ]}
            children={<Projects />}
          />
        ),
      },
      {
        path: "projects/:id/version/:version",
        element: (
          <RolesAuthRoute
            rolesRoute={[
              ROLES.ROLE_SUPER_ADMIN,
              ROLES.ROLE_ADMIN_USER,
              ROLES.ROLE_USER,
            ]}
            children={<ProjectsInfo />}
          />
        ),
        children: [
          { index: true, element: <Measurements /> },
          { path: "details", element: <Details /> },
          {
            path: "installation-progress",
            element: <InstallationProgress />,
          },
          { path: "measurements", element: <Measurements /> },
        ],
      },
      {
        path: "groups",
        element: <RolesAuthRoute children={<Groups />} />,
      },
    ],
  },
];
