import React from "react";
import type { MenuProps } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuthUser } from "react-auth-kit";
import { ROLES } from "../../utils/roles";

type MenuItem = Required<MenuProps>["items"][number];
const getItem = (
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem => {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
};

const useGetItems = () => {
  const authUser = useAuthUser();
  const user = authUser();

  const items: MenuItem[] = [
    // getItem("User", "dashboard", <FontAwesomeIcon icon="users" />, [getItem("Admins", "users")]),
  ];

  switch (user?.role) {
    case ROLES.ROLE_SUPER_ADMIN:
      items.push(
        getItem("Project", "projects", <FontAwesomeIcon icon="folder-open" />)
      );
      /*    items.push(
        getItem(
          "Balcony Types",
          "balcony-types",
          <FontAwesomeIcon icon="book" />
        )
      ); */
      items.push(
        getItem(
          "Default Measures",
          "default-measures",
          <FontAwesomeIcon icon="ruler-horizontal" />
        )
      );
      items.push(
        getItem("Groups", "groups", <FontAwesomeIcon icon="user-group" />)
      );
      items.push(getItem("Users", "users", <FontAwesomeIcon icon="users" />));

      /*     items.push(
        getItem("Sides", "sides", <FontAwesomeIcon icon="box" />, [
          getItem("Sides", "sides/list"),
          getItem("Calculations", "sides/calculations"),
        ])
      ); */

      break;
    case ROLES.ROLE_RESELLER:
      items.push(
        getItem("Project", "projects", <FontAwesomeIcon icon="folder-open" />)
      );
      items.push(getItem("Users", "users", <FontAwesomeIcon icon="users" />));
      /*    items.push(
        getItem(
          "Balcony Types",
          "balcony-types",
          <FontAwesomeIcon icon="chevron-circle-right" />
        )
      ); */
      items.push(
        getItem(
          "Default Measures",
          "default-measures",
          <FontAwesomeIcon icon="ruler-horizontal" />
        )
      );
      /*    items.push(
        getItem("Sides", "sides", <FontAwesomeIcon icon="box" />, [
          getItem("Sides", "sides/list"),
          getItem("Calculations", "sides/calculations"),
        ])
      ); */
      break;
    case ROLES.ROLE_USER:
      items.push(
        getItem("Project", "projects", <FontAwesomeIcon icon="folder-open" />)
      );
      break;

    case ROLES.ROLE_ADMIN_USER:
      items.push(
        getItem("Project", "projects", <FontAwesomeIcon icon="folder-open" />)
      );
      break;

    default:
  }

  return items;
};

export default useGetItems;
