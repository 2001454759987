import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Row,
  FormInstance,
  InputNumber,
  Select,
} from "antd";
import { useAuthUser } from "react-auth-kit";

import { YOUR_GOOGLE_MAPS_API_KEY } from "../../utils/constants";
import { Autocomplete, useLoadScript } from "@react-google-maps/api";
import { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { ROLES } from "../../utils/roles";
import { getUsers } from "../../redux/users/actions";
const placesLibrary: any = ["drawing", "geometry", "places"];

/* eslint-disable react-hooks/exhaustive-deps */
const ProjectsForm = ({
  disabledId,
  form,
  onCancel,
  onFinish,
}: {
  disabledId?: boolean;
  form: FormInstance;
  onCancel: any;
  onFinish: any;
}) => {
  const dispatch = useAppDispatch();

  const [searchResult, setSearchResult] = useState<any>({});
  const [searchInput, setSearchInput] = useState("");
  const groups = useAppSelector((state) => state.groupsReducer.groups);
  const users = useAppSelector((state) => state.usersReducer.users);

  const authUser = useAuthUser();

  const user = authUser();

  useEffect(() => {
    getUsersList();
    setSearchInput(form.getFieldValue("address_line_1"));
    return () => {
      setSearchInput("");
    };
  }, []);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: YOUR_GOOGLE_MAPS_API_KEY,
    libraries: placesLibrary,
  });

  const onLoad = (autocomplete: any) => {
    setSearchResult(autocomplete);
  };

  const onPlaceChanged = () => {
    if (searchResult != null) {
      const place = searchResult.getPlace();
      const { address_components } = place;

      const number = getAttr(address_components, "street_number")?.short_name;
      const route = getAttr(address_components, "route")?.short_name;
      const address_line_2 = getAttr(
        address_components,
        "subpremise"
      )?.short_name;
      const city = getAttr(address_components, "locality")?.short_name;
      const province = getAttr(
        address_components,
        "administrative_area_level_1"
      )?.short_name;
      const postal_code = getAttr(
        address_components,
        "postal_code"
      )?.short_name;
      let address_line_1 = (number ? number + " " : "") + route ? route : "";
      if (!address_line_1) address_line_1 = place.formatted_address;

      setSearchInput(address_line_1);
      form.setFieldsValue({
        address_line_1: address_line_1,
        address_line_2,
        province,
        city,
        postal_code,
      });
    }
  };

  const getAttr = (array: any[], key: string) => {
    return (
      array.find((el: any) => el.types.some((type: any) => type === key)) ||
      false
    );
  };

  const getUsersList = (page = 1) => {
    dispatch(getUsers(page, form.getFieldValue("group_id")));
  };
  return (
    <Form
      className="form-allunox"
      layout="vertical"
      name="users"
      form={form}
      onFinish={onFinish}
      scrollToFirstError
    >
      <Row gutter={24}>
        <Col span={18}>
          <Form.Item
            name="project_id"
            label="Project ID"
            rules={[{ required: true, message: "Filed is required" }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name="floors"
            label="Nº Floors"
            rules={[{ required: true, message: "Filed is required" }]}
          >
            <InputNumber min={0} autoComplete="off" />
          </Form.Item>
        </Col>

        {/*   <Col span={24}>
          <Form.Item name="engineering" valuePropName="checked">
            <Checkbox>Engineering</Checkbox>
          </Form.Item>
        </Col> */}
      </Row>

      <Divider />
      <Row gutter={24}>
        <Col span={24}>
          <Form.Item
            name="client"
            label="Client"
            rules={[{ required: true, message: "Filed is required" }]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col span="24">
          <Form.Item
            label="Address line 1"
            name="address_line_1"
            rules={[{ required: true, message: "Filed is required", min: 11 }]}
          >
            {/*             {isLoaded ? (
              <>
                <Autocomplete onPlaceChanged={onPlaceChanged} onLoad={onLoad}>
                  <Input
                    value={searchInput}
                    onChange={(e) => {
                      form.setFieldValue(
                        "address_line_1",
                        e.currentTarget.value
                      );
                      setSearchInput(e.currentTarget.value);
                    }}
                    autoComplete="off"
                    placeholder=""
                  />
                </Autocomplete>
              </>
            ) : (
            )} */}
            <Input autoComplete="off" />
          </Form.Item>
        </Col>
        <Col span="24">
          <Form.Item name="address_line_2" label="Address line 2 (optional)">
            <Input autoComplete="off" />
          </Form.Item>
        </Col>
        <Col span={24} sm={12}>
          <Form.Item
            name="province"
            label="Province"
            rules={[{ required: true, message: "Filed is required" }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={24} sm={12}>
          <Form.Item
            name="city"
            label="City"
            rules={[{ required: true, message: "Filed is required" }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={24} sm={12}>
          <Form.Item
            name="postal_code"
            label="Postal Code"
            rules={[{ required: true, message: "Filed is required" }]}
          >
            <Input />
          </Form.Item>
        </Col>
        {user?.role === ROLES.ROLE_SUPER_ADMIN ? (
          <Col span={24} sm={12}>
            <Form.Item
              name="group_id"
              label="Group"
              rules={[{ required: true, message: "Filed is required" }]}
            >
              <Select onChange={(value) => getUsersList(1)}>
                {groups?.map((item: any, i: number) => (
                  <Select.Option key={i} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        ) : (
          <> </>
        )}
        {user?.role === ROLES.ROLE_SUPER_ADMIN ||
        user?.role === ROLES.ROLE_ADMIN_USER ? (
          <Col span={24} sm={12}>
            <Form.Item
              style={{ marginBottom: "39px" }}
              label="Technical"
              name="user_id"
              rules={[{ required: true, message: "This field is required" }]}
            >
              <Select>
                {users?.data?.map((item: any, i: number) => (
                  <Select.Option key={i} value={item.id}>
                    {item?.user_detail?.first_name}{" "}
                    {item?.user_detail?.last_name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        ) : (
          <> </>
        )}
      </Row>

      <Form.Item name="id" hidden>
        <Input />
      </Form.Item>

      <Form.Item style={{ textAlign: "end", marginBottom: 0, marginTop: 10 }}>
        {!form.getFieldValue("id") && (
          <Button
            onClick={onCancel}
            style={{ marginRight: 10 }}
            htmlType="button"
            type="default"
          >
            Cancel
          </Button>
        )}
        <Button htmlType="submit" type="primary">
          {form.getFieldValue("id") ? "Update" : "Save Project"}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ProjectsForm;
