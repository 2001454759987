import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Badge,
  Button,
  Card,
  Col,
  Form,
  Image,
  Input,
  Modal,
  Pagination,
  Row,
  Spin,
  Table,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { useAuthUser } from "react-auth-kit";
import { useNavigate } from "react-router";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  getProjects,
  saveProject,
  updateProject,
} from "../../redux/projects/actions";
import { getAddressFormat } from "../../utils/getFormats";
import { ROLES } from "../../utils/roles";
import ProjectsForm from "./projectsForm";
import GroupSelect from "./components/groupSelect";
import { getGroups } from "../../redux/groups/actions";

const { Title, Paragraph, Text } = Typography;

/* eslint-disable react-hooks/exhaustive-deps */
function Projects() {
  const [visibleForm, setVisibleForm] = useState(false);
  const [visibleObservations, setVisibleObservations] = useState(false);
  const [form] = Form.useForm();
  const authUser = useAuthUser();
  const user = authUser();

  const dispatch = useAppDispatch();
  const projects = useAppSelector((state) => state.projectsReducer.projects);
  const project = useAppSelector((state) => state.projectsReducer.project);
  const loaderProjects = useAppSelector(
    (state) => state.projectsReducer.loaderProjects
  );
  const navigate = useNavigate();

  useEffect(() => {
    getData(projects?.current_page);
    if (
      user?.role === ROLES.ROLE_SUPER_ADMIN ||
      user?.role === ROLES.ROLE_ADMIN_USER
    ) {
      dispatch(getGroups());
    }
  }, []);

  useEffect(() => {
    if (project.id) {
      form.setFieldsValue(project);
    }
  }, [project]);

  const data: any[] = projects?.data;
  const getData = (page = 1) => {
    dispatch(getProjects(page));
  };

  const onFinish = (data: any) => {
    if (!data.id) {
      dispatch(saveProject(data));
    } else {
      dispatch(updateProject(data, data.id));
    }

    handleCancel();
  };

  const handleCancel = () => {
    form.resetFields();
    setVisibleForm(false);
    setVisibleObservations(false);
  };

  const showNotes = (id: any) => {
    setVisibleObservations(true);
  };

  const propsPagination = {
    current: projects?.current_page,
    pageSize: projects?.per_page || 0,
    hideOnSinglePage: true,
    total: projects?.total,
    showSizeChanger: false,
    showTotal: (total: number, range: any[]) =>
      `${range[0]}-${range[1]} of ${total} items`,
    onChange: getData,
  };

  return (
    <Spin spinning={loaderProjects}>
      <div className="input-search">
        <Input
          style={{ maxWidth: 400, padding: "6px" }}
          type="text"
          suffix={<FontAwesomeIcon icon="search" />}
          placeholder="Project ID"
        />
      </div>

      <Row
        gutter={24}
        style={{
          maxWidth: "1080px",
          justifyContent: "center",
          alignItems: "flex-start",
          margin: "auto",
        }}
      >
        {user?.role === ROLES.ROLE_SUPER_ADMIN ? (
          <>
            <Col span={18}></Col>
            <Col
              span={6}
              style={{
                marginBottom: "20px",
              }}
            >
              <GroupSelect />
            </Col>
          </>
        ) : (
          <></>
        )}

        {user?.role === ROLES.ROLE_SUPER_ADMIN ||
        user?.role === ROLES.ROLE_ADMIN_USER ? (
          <Col
            style={{
              minWidth: "216px",
            }}
          >
            <div
              className="btn-add-project"
              onClick={() => {
                form.resetFields();
                setVisibleForm(true);
              }}
            >
              <div className="card-btn">
                <FontAwesomeIcon icon="plus" />
              </div>
              Add New Project
            </div>
            <br />
          </Col>
        ) : (
          <></>
        )}
        {data?.map((el, i) => (
          <Col key={i}>
            <Card className="card-project">
              <Row style={{ marginTop: "-15px" }}>
                <Col
                  span={24}
                  className="cursor-pointer"
                  onClick={() => {
                    navigate(
                      `${el?.id.toString()}/version/${el?.version.toString()}`
                    );
                  }}
                >
                  Project ID
                  <Paragraph className="text cursor-pointer">
                    {el?.project_id}
                  </Paragraph>
                </Col>

                <Col span={12}>Address</Col>
                <Col span={12} style={{ textAlign: "center" }}>
                  <Button
                    type="link"
                    href={`https://www.google.com/maps/place/${getAddressFormat(
                      el?.project_address
                    )}`}
                    target="_blank"
                  >
                    <FontAwesomeIcon
                      style={{ color: "red" }}
                      icon="map-marker-alt"
                    />
                  </Button>
                </Col>
                <Col span={24}>
                  <Paragraph className="text">
                    {getAddressFormat(el?.project_address)}
                  </Paragraph>
                </Col>

                <Col span={24}>
                  {/*  Progress
                  <Paragraph className="text">
                    <b
                      style={{
                        color:
                          (el?.floors?.length * 10) / el?.floors?.length === 10
                            ? "green"
                            : (el?.floors?.length * 10) / el?.floors?.length >=
                              5
                            ? "orange"
                            : "red",
                      }}
                    >
                      {el?.floors?.length + "/" + el?.floors?.length}
                    </b>
                  </Paragraph> */}
                </Col>
              </Row>

              <div className="actions">
                <Badge
                  style={{
                    marginTop: "-5px",
                    marginRight: "-5px",
                    cursor: "default",
                    fontSize: "11px",
                  }}
                  size="small"
                  count={el.notes}
                  offset={[-10, 1]}
                >
                  {/*       <Button
                    size="small"
                    onClick={() => {
                      showNotes(el?.id);
                    }}
                  >
                    Notes
                  </Button> */}
                </Badge>
                <Button
                  size="small"
                  style={{ marginLeft: 10 }}
                  type="primary"
                  onClick={() => {
                    navigate(
                      `${el?.id.toString()}/version/${el?.version.toString()}`
                    );
                  }}
                >
                  Show Info
                </Button>
              </div>
            </Card>
          </Col>
        ))}
        <Col span={24} style={{ textAlign: "right", marginTop: 20 }}>
          <Pagination size="small" {...propsPagination} />
        </Col>
      </Row>

      <Modal
        title={<></>}
        open={visibleForm}
        footer={<></>}
        onCancel={handleCancel}
        width="700px"
        destroyOnClose
        centered
      >
        <div style={{ textAlign: "center" }}>
          <br />
          <Image
            preview={false}
            style={{ width: "100%" }}
            src={require("../../static/img/footer-logo.png")}
            alt="..."
          />
          <br />
        </div>

        <ProjectsForm form={form} onFinish={onFinish} onCancel={handleCancel} />
      </Modal>

      <Modal
        title={<></>}
        open={visibleObservations}
        footer={<></>}
        onCancel={handleCancel}
        width="500px"
      >
        <div style={{ textAlign: "center" }}>
          <br />
          <Image
            preview={false}
            style={{ width: "100%" }}
            src={require("../../static/img/footer-logo.png")}
            alt="..."
          />
          <br />
        </div>

        <Table
          columns={[
            {
              key: "observation",
              dataIndex: "observation",
              render: (value, record, i) => {
                return (
                  <React.Fragment key={i}>
                    <Title level={3}>Current Observations:</Title>

                    <div>
                      <Text strong>Juan Manuel Mata</Text>
                      <br />
                      <Text mark>2023-02-02 13:34:22</Text>
                    </div>
                    <br />
                    <Paragraph>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum.
                    </Paragraph>
                  </React.Fragment>
                );
              },
            },
          ]}
          dataSource={[{ key: 1 }, { key: 2 }, { key: 13 }]}
          pagination={{ hideOnSinglePage: true, pageSize: 1 }}
        />
      </Modal>
    </Spin>
  );
}

export default Projects;
