import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Avatar,
  Button,
  Card,
  Descriptions,
  Divider,
  Form,
  Image,
  Modal,
  Popconfirm,
  Table,
} from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  getBalconyTypes,
  saveBalconyType,
  updateBalconyType,
  getBalconyTypeById,
  deleteBalconyType,
  cleanBalconyType,
} from "../../redux/balconyType/actions";
import BalconyTypesForm from "./balconyTypesForm";

/* eslint-disable react-hooks/exhaustive-deps */
const BalconyTypes = () => {
  const [visibleForm, setVisibleForm] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const balconyTypes = useAppSelector(
    (state) => state.balconyTypesReducer.balconyTypes
  );
  const balconyType = useAppSelector(
    (state) => state.balconyTypesReducer.balconyType
  );
  const loaderBalconyTypes = useAppSelector(
    (state) => state.balconyTypesReducer.loaderBalconyTypes
  );

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (balconyType.id) {
      form.setFieldsValue(balconyType);
    }
  }, [balconyType]);

  const getData = (page = 1) => {
    dispatch(getBalconyTypes(page));
    console.log("from component", balconyTypes);
  };

  const showModalForm = (id: number) => {
    if (id) {
      dispatch(getBalconyTypeById(id));
    }

    setVisibleForm(true);
  };

  const actionsMarkup = (id: number) => {
    return (
      <div className="table-actions">
        <>
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => dispatch(deleteBalconyType(id))}
          >
            <Button type="text">
              <FontAwesomeIcon icon="trash-alt" />
            </Button>
          </Popconfirm>

          <Button
            className="color-primary"
            onClick={() => showModalForm(id)}
            type="text"
          >
            <FontAwesomeIcon icon="edit" />
          </Button>
        </>
      </div>
    );
  };

  const colums: ColumnsType<any> = [
    {
      dataIndex: "Balcony Type",
      key: "use",
      responsive: ["xs"],
      render: (value, record) => {
        return (
          <Descriptions bordered>
            <Descriptions.Item label="Figure:">
              {record?.figure}
            </Descriptions.Item>
            <Descriptions.Item label="Name:">{record?.name}</Descriptions.Item>
            <Descriptions.Item label="Sides:">
              {record?.sides}
            </Descriptions.Item>
            <Divider />
            <Descriptions.Item label="Actions:">
              {record?.actions}
            </Descriptions.Item>
          </Descriptions>
        );
      },
    },
    {
      title: "Figure",
      dataIndex: "figure",
      key: "figure",
      responsive: ["sm"],
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      responsive: ["sm"],
    },
    {
      title: "Sides",
      dataIndex: "sides",
      key: "sides",
      responsive: ["sm"],
    },

    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      responsive: ["sm"],
    },
  ];

  const data = balconyTypes?.data?.map((el: any) => {
    const { id, name, figure, sides } = el;
    return {
      key: id,
      name,
      figure: <Avatar shape="square" size="large" src={figure} />,
      sides: sides?.length,

      actions: actionsMarkup(id),
    };
  });

  const onFinish = (data: any) => {
    if (data?.figure?.file) {
      data.figure = data.figure.file;
    } else {
      delete data?.figure;
    }

    let formData: any = new FormData();
    Object.keys(data).forEach((name) => {
      formData.append(name, data[name]);
    });

    if (!data.id) {
      dispatch(saveBalconyType(formData));
    } else {
      /*       dispatch(updateBalconyType(data, data.id));
       */
    }

    handleCancel();
  };

  const handleCancel = () => {
    form.resetFields();
    dispatch(cleanBalconyType());
    setVisibleForm(false);
  };

  return (
    <Card title="Balcony Types">
      <div style={{ textAlign: "end" }}>
        <Button
          onClick={() => {
            setVisibleForm(true);
          }}
          type="primary"
        >
          Add Balcony Type
        </Button>
        <br />
        <br />
      </div>
      <div className="table-responsive">
        <Table
          bordered
          loading={loaderBalconyTypes}
          columns={colums}
          dataSource={data}
          pagination={{
            defaultPageSize: 10,
            current: balconyTypes?.current_page,
            pageSize: balconyTypes?.per_page,
            hideOnSinglePage: true,
            total: balconyTypes?.total,
            showSizeChanger: false,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
            onChange: getData,
          }}
        />
      </div>

      <Modal
        title={<>{balconyType.id ? "Update" : "Add"} Balcony Type</>}
        open={visibleForm}
        footer={<></>}
        centered
        onCancel={handleCancel}
        destroyOnClose
      >
        <div style={{ textAlign: "center" }}>
          <br />
          <Image
            preview={false}
            style={{ width: "100%" }}
            src={require("../../static/img/footer-logo.png")}
            alt="..."
          />
          <br />
          <br />
        </div>

        <BalconyTypesForm
          form={form}
          onFinish={onFinish}
          onCancel={handleCancel}
        />
      </Modal>
    </Card>
  );
};

export default BalconyTypes;
