import { version } from "os";
import api from "../../api";

export const actions = {
  SUCCESS_PROJECT: "SUCCESS_PROJECT",
  SUCCESS_PROJECTS: "SUCCESS_PROJECTS",
  SUCCESS_ROLES: "SUCCESS_ROLES",
  CLEAN_PROJECT: "CLEAN_PROJECT",
  ACTION_BEGIN: "ACTION_BEGIN",
  ERROR: "ERROR",
  SUCCESS_BALCONYTYPES: "SUCCESS_BALCONYTYPES",
  SUCCESS_BALCONY_NOTE: "SUCCESS_BALCONY_NOTE",
};

const path = "/projects";
const pathFloor = "/floor";
const pathBalcony = "/balcony";

const begin = (data: any) => {
  return (dispatch: any) => {
    dispatch({
      type: actions.ACTION_BEGIN,
      data,
    });
  };
};

const error = (err: any) => {
  return { type: actions.ERROR, err };
};

const getProjects = (page?: number, data?: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(begin({ loaderProjects: true }));

      const response = await api.get(`${path}${page ? "?page=" + page : ""}`, {
        params: data,
      });
      if (response.status >= 200 && response.status <= 201) {
        dispatch(begin({ loaderProjects: false }));
        const { data } = response;

        dispatch({
          type: actions.SUCCESS_PROJECTS,
          data: data.data,
        });
        return;
      }

      dispatch(error(response?.data?.message));
      return response?.data?.message;
    } catch (err) {
      dispatch(error(err));
      return err;
    }
  };
};

const saveProject = (data: any) => {
  return async (dispatch: any) => {
    try {
      const response = await api.post(`${path}`, data);
      if (response.status >= 200 && response.status <= 201) {
        dispatch(getProjects());
        return;
      }

      dispatch(error(response?.data?.message));
      return response?.data?.message;
    } catch (err) {
      dispatch(error(err));
      return err;
    }
  };
};

const updateProject = (data: any, id: number) => {
  return async (dispatch: any) => {
    try {
      dispatch(begin({ loaderProjects: true }));
      const response = await api.put(`${path}/${id}`, data);
      dispatch(begin({ loaderProjects: false }));
      if (response.status >= 200 && response.status <= 201) {
        dispatch(getProjects());
        return;
      }

      dispatch(error(response?.data?.message));
      return response?.data?.message;
    } catch (err) {
      dispatch(error(err));
      return err;
    }
  };
};

const deleteProject = (id: number) => {
  return async (dispatch: any) => {
    try {
      const response = await api.delete(`${path}/${id}`);
      if (response.status >= 200 && response.status <= 201) {
        dispatch(getProjects());
        return true;
      }

      dispatch(error(response?.data?.message));
      return false;
    } catch (err) {
      dispatch(error(err));
      return false;
    }
  };
};

const getProjectById = (id: any, data?: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(begin({ loaderProjects: true }));
      const response = await api.get(`${path}/${id}`, {
        params: data,
      });
      dispatch(begin({ loaderProjects: false }));

      if (response.status >= 200 && response.status <= 201) {
        const { data } = response;

        dispatch({
          type: actions.SUCCESS_PROJECT,
          data: data.data,
        });
        return;
      }

      dispatch(error(response?.data?.message));
      return response?.data?.message;
    } catch (err) {
      dispatch(error(err));
      return err;
    }
  };
};

const saveFloor = (projects_id: any) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch(begin({ loaderFloors: true }));
      const response = await api.post(`${pathFloor}`, { projects_id });
      dispatch(begin({ loaderFloors: false }));
      if (response.status >= 200 && response.status <= 201) {
        dispatch(
          getProjectById(getState().projectsReducer.project.id, {
            version: Number(
              getState().projectsReducer?.project?.actually_version
            ),
          })
        );
        return;
      }

      dispatch(error(response?.data?.message));
      return response?.data?.message;
    } catch (err) {
      dispatch(error(err));
      return err;
    }
  };
};

const updateFloor = (data: any, id: number) => {
  return async (dispatch: any, getState: any) => {
    try {
      const response = await api.put(`${pathFloor}/${id}`, data);

      if (response.status >= 200 && response.status <= 201) {
        dispatch(
          getProjectById(getState().projectsReducer.project.id, {
            version: Number(
              getState().projectsReducer?.project?.actually_version
            ),
          })
        );
        return;
      }

      dispatch(error(response?.data?.message));
      return response?.data?.message;
    } catch (err) {
      dispatch(error(err));
      return err;
    }
  };
};

const saveBalcony = (data: { floor_id: number; quantity: number }) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch(begin({ loaderBalconies: true }));
      const response = await api.post(`${pathBalcony}`, data);
      dispatch(begin({ loaderBalconies: false }));
      if (response.status >= 200 && response.status <= 201) {
        dispatch(
          getProjectById(getState().projectsReducer.project.id, {
            version: Number(
              getState().projectsReducer?.project?.actually_version
            ),
          })
        );
        return;
      }

      dispatch(error(response?.data?.message));
      return response?.data?.message;
    } catch (err) {
      dispatch(error(err));
      return err;
    }
  };
};

const updateBalcony = (data: any, id: number) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch(begin({ loaderBalconies: true }));
      const response = await api.put(`${pathBalcony}/${id}`, data);
      dispatch(begin({ loaderBalconies: false }));
      if (response.status >= 200 && response.status <= 201) {
        dispatch(
          getProjectById(getState().projectsReducer.project.id, {
            version: Number(
              getState().projectsReducer?.project?.actually_version
            ),
          })
        );
        return;
      }

      dispatch(error(response?.data?.message));
      return response?.data?.message;
    } catch (err) {
      dispatch(error(err));
      return err;
    }
  };
};

const addVersionBalcony = (id: number, data?: any) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch(begin({ loaderBalconies: true }));
      const response = await api.post(`/projects/addVersion/${id}`, data);
      dispatch(begin({ loaderBalconies: false }));

      if (response.status >= 200 && response.status <= 201) {
        dispatch(
          getProjectById(getState().projectsReducer.project.id, {
            version:
              Number(getState().projectsReducer?.project?.actually_version) + 1,
          })
        );
        return;
      }

      dispatch(error(response?.data?.message));
      return response?.data?.message;
    } catch (err) {
      dispatch(error(err));
      return err;
    }
  };
};

const saveBalconyMeasurement = (
  data: any,
  idBalconyMeasurements: number,
  version?: any
) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch(begin({ loaderBalconies: true }));
      const payload = {
        ...data,
        version: version,
      };
      const response = await api.put(
        `/balcony/${idBalconyMeasurements}`,
        payload
      );
      dispatch(begin({ loaderBalconies: false }));

      if (response.status >= 200 && response.status <= 201) {
        dispatch(
          getProjectById(getState().projectsReducer.project.id, {
            version: version,
          })
        );
        return response.status;
      }

      dispatch(error(response?.data?.message));
      return response?.data?.message;
    } catch (err) {
      dispatch(error(err));
      return err;
    }
  };
};

const getBalconyTypes = (page?: number, resourcesPerPage?: number) => {
  return async (dispatch: any) => {
    try {
      const response = await api.get(`/balcony-types`);
      if (response.status >= 200 && response.status <= 201) {
        const { data } = response;
        dispatch({
          type: actions.SUCCESS_BALCONYTYPES,
          data: data.data,
        });
        return data.data;
      }

      dispatch(error(response?.data?.message));
      return response?.data?.message;
    } catch (err) {
      dispatch(error(err));
      return err;
    }
  };
};

const cloneBalcony = (id: number, data: any) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch(begin({ loaderBalconies: true }));
      const response = await api.post(pathBalcony + `/clone/${id}`, data);
      dispatch(begin({ loaderBalconies: false }));
      if (response.status >= 200 && response.status <= 201) {
        dispatch(
          getProjectById(getState().projectsReducer.project.id, {
            version: Number(
              getState().projectsReducer?.project?.actually_version
            ),
          })
        );
        return;
      }

      dispatch(error(response?.data?.message));
      return response?.data?.message;
    } catch (err) {
      dispatch(error(err));
      return err;
    }
  };
};

const deleteBalcony = (id: number) => {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch(begin({ loaderBalconies: true }));
      const response = await api.delete(pathBalcony + `/${id}`);
      dispatch(begin({ loaderBalconies: false }));
      if (response.status >= 200 && response.status <= 201) {
        dispatch(
          getProjectById(getState().projectsReducer.project.id, {
            version: Number(
              getState().projectsReducer?.project?.actually_version
            ),
          })
        );
        return true;
      }

      dispatch(error(response?.data?.message));
      return false;
    } catch (err) {
      dispatch(error(err));
      return false;
    }
  };
};

const getBalconyVersion = (id: number, data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(begin({ loaderBalconies: true }));
      const response = await api.get(
        `/balcony/version/${id}?version=${data.version}`
      );
      dispatch(begin({ loaderBalconies: false }));
      if (response.status >= 200 && response.status <= 201) {
        return response.data.data;
      }

      dispatch(error(response?.data?.message));
      return response?.data?.message;
    } catch (err) {
      dispatch(error(err));
      return err;
    }
  };
};
const updateBalconyType = (data: any, id: number) => {
  return async (dispatch: any) => {
    try {
      const response = await api.put(`${pathBalcony}/updateType/${id}`, data);
      if (response.status >= 200 && response.status <= 201) {
        dispatch(getBalconyTypes());
        return;
      }

      dispatch(error(response?.data?.message));
      return response?.data?.message;
    } catch (err) {
      dispatch(error(err));
      return err;
    }
  };
};

const overrideDefaultMeasurements = (data: any, id: number) => {
  return async (dispatch: any, getState: any) => {
    try {
      const response = await api.post(`${pathBalcony}/override/${id}`, data);
      if (response.status >= 200 && response.status <= 201) {
        dispatch(
          getProjectById(getState().projectsReducer.project.id, {
            version: getState().projectsReducer.project.version,
          })
        );

        return response?.data?.message;
      }

      dispatch(error(response?.data?.message));
      return response?.data?.message;
    } catch (err) {
      dispatch(error(err));
      return err;
    }
  };
};

const getProjectPDF = (id: number, data: any) => {
  return async (dispatch: any) => {
    try {
      const response = await api.get(`projects/pdf/${id}`, {
        responseType: "blob",
        params: data,
      });
      if (response.status >= 200 && response.status <= 201) {
        return response.data;
      }

      dispatch(error(response?.data?.message));
      return response?.data?.message;
    } catch (err) {
      dispatch(error(err));
      return err;
    }
  };
};

const getBalconyPDF = (id: number, data: any) => {
  return async (dispatch: any) => {
    try {
      const response = await api.get(`${pathBalcony}/pdf/${id}`, {
        responseType: "blob",
        params: data,
      });
      if (response.status >= 200 && response.status <= 201) {
        return response.data;
      }

      dispatch(error(response?.data?.message));
      return response?.data?.message;
    } catch (err) {
      dispatch(error(err));
      return err;
    }
  };
};

const deleteFloor = (id: number) => {
  return async (dispatch: any, getState: any) => {
    try {
      const response = await api.delete(`${pathFloor}/${id}`);
      if (response.status >= 200 && response.status <= 201) {
        dispatch(
          getProjectById(getState().projectsReducer.project.id, {
            version: getState().projectsReducer.project.version,
          })
        );
        return true;
      }

      dispatch(error(response?.data?.message));
      return false;
    } catch (err) {
      dispatch(error(err));
      return false;
    }
  };
};

const downloadPlans = (id: number, data?: any) => {
  return async (dispatch: any) => {
    try {
      const response = await api.get(`projects/${id}/plans`, {
        params: data,
      });
      if (response.status >= 200 && response.status <= 201) {
        return response.data;
      }

      dispatch(error(response?.data?.message));
      return response?.data?.message;
    } catch (err) {
      dispatch(error(err));
      return err;
    }
  };
};

const updateInstallationProcess = (data: any, id: number) => {
  return async (dispatch: any, getState: any) => {
    try {
      const response = await api.put(`${pathBalcony}/${id}/installment`, data);
      if (response.status >= 200 && response.status <= 201) {
        dispatch(
          getProjectById(getState().projectsReducer.project.id, {
            version: getState().projectsReducer.project.version,
          })
        );
        return response.data;
      }
      dispatch(error(response?.data?.message));
      return response?.data?.message;
    } catch (err) {
      dispatch(error(err));
      return err;
    }
  };
};

const createBalconyNote = (data: any, id: number) => {
  return async (dispatch: any, getState: any) => {
    try {
      const response = await api.post(`${pathBalcony}/comment/${id}`, data);
      if (response.status >= 200 && response.status <= 201) {
        dispatch(
          getProjectById(getState().projectsReducer.project.id, {
            version: getState().projectsReducer.project.version,
          })
        );
        dispatch({
          type: actions.SUCCESS_BALCONY_NOTE,
          data: response.data.data,
        });
        return response.data;
      }
      dispatch(error(response?.data?.message));
      return response?.data?.message;
    } catch (err) {
      dispatch(error(err));
      return err;
    }
  };
};

const cleanProject = () => {
  return async (dispatch: any) => {
    dispatch({ type: actions.CLEAN_PROJECT });
  };
};

export {
  begin,
  getProjects,
  saveProject,
  updateProject,
  getProjectById,
  deleteProject,
  saveFloor,
  updateFloor,
  saveBalcony,
  updateBalcony,
  addVersionBalcony,
  saveBalconyMeasurement,
  getBalconyTypes,
  cleanProject,
  cloneBalcony,
  deleteBalcony,
  getBalconyVersion,
  updateBalconyType,
  overrideDefaultMeasurements,
  getProjectPDF,
  getBalconyPDF,
  deleteFloor,
  downloadPlans,
  updateInstallationProcess,
  createBalconyNote,
};
