import { Checkbox, Popconfirm, Spin, Table } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { updateFloor } from "../../../redux/projects/actions";
import { EditText } from "react-edit-text";

const Details = () => {
  const dispatch = useAppDispatch();
  const project = useAppSelector((state) => state.projectsReducer.project);
  const loaderFloors = useAppSelector(
    (state) => state.projectsReducer.loaderFloors
  );

  const onUpdateFloor = (name: string, floors_id: number) => {
    dispatch(updateFloor({ name }, floors_id));
  };

  return (
    <Spin spinning={loaderFloors}>
      <div className="table-responsive">
        <Table
          className="table-allunox"
          style={{ margin: "auto" }}
          bordered={false}
          pagination={{ hideOnSinglePage: true }}
          size="small"
          rowClassName={(record, index) => (index % 2 === 0 ? "even" : "")}
          columns={[
            {
              render: (record) => {
                return (
                  <div style={{ textAlign: "left" }}>
                    {record.floor}
                    <br />
                    Balconies: {record.balconies}
                    <br />
                    Complete: {record.complete}
                    <br />
                    <br />
                    <div style={{ textAlign: "right" }}>
                      <b style={{ padding: "0 1rem" }}>actions</b>
                      <br />
                    </div>
                  </div>
                );
              },
              responsive: ["xs"],
            },
            {
              key: "floor",
              dataIndex: "floor",
              className: "br-gray",
              responsive: ["sm"],
            },
            {
              key: "balconies",
              dataIndex: "balconies",
              className: "br-gray",
              responsive: ["sm"],
            },
            {
              key: "measurement",
              dataIndex: "measurement",
              title: "Measurement",
              className: "br-gray",
              responsive: ["sm"],
            },
            {
              key: "validate",
              dataIndex: "validate",
              title: "Validate",
              className: "br-gray",
              responsive: ["sm"],
            },
            {
              key: "actions",
              dataIndex: "actions",
              title: "Actions",
              responsive: ["sm"],
            },
          ]}
          dataSource={project?.floors?.map((data: any, i: number) => {
            return {
              key: i,
              floor: (
                <EditText
                  inputClassName="td-edit-input"
                  className="td-edit"
                  placeholder="Floor Nº"
                  defaultValue={data.name}
                  onSave={(e) => onUpdateFloor(e.value, data.id)}
                />
              ),
              balconies: data.balconies?.length
                ? data.balconies?.length?.toString()
                : 0,
              measurement: (
                <Checkbox className="checkbox-allunox" type="checkbox" />
              ),
              validate: (
                <Checkbox className="checkbox-allunox" type="checkbox" />
              ),
              actions: (
                <Popconfirm
                  title="Delete the floor"
                  description="Are you sure to delete this floor?"
                  onConfirm={() => {}}
                  onCancel={() => {}}
                  okText="Yes"
                  cancelText="No"
                >
                  <FontAwesomeIcon
                    style={{ cursor: "pointer" }}
                    icon="trash-alt"
                  />
                </Popconfirm>
              ),
            };
          })}
        />
      </div>
    </Spin>
  );
};

export default Details;
