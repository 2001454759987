import jwt_decode from "jwt-decode";
import api from "../../api";
export const actions = {
  ACTION_BEGIN: "ACTION_BEGIN",
  ERROR: "ERROR",
};

const begin = (data: any) => {
  return (dispatch: any) => {
    dispatch({
      type: actions.ACTION_BEGIN,
      data,
    });
  };
};

const error = (err: any) => {
  return { type: actions.ERROR, err };
};

const actionLoginSuper = (data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(begin({ loading: true }));
      const response = await api.post("/auth/login", data);
      if (response.status >= 200 && response.status <= 201) {
        dispatch(begin({ loading: false }));
        const { data } = response;

        const payload = {
          data: {
            token: data.token,
            expiresIn: 0,
            authState: data.user,
          },
        };

        const decoded: any = jwt_decode(payload.data.token);
        payload.data.expiresIn = decoded.exp;

        return payload;
      }

      dispatch(error(response?.data?.message));
      return response?.data?.message;
    } catch (error) {
      return error;
    }
  };
};

const checkUserRole = (value: string) => {
  return async (dispatch: any) => {
    try {
      const response = await api.get("/auth/role?value=" + value);
      if (response.status >= 200 && response.status <= 201) {
        return response.data;
      }

      dispatch(error(response?.data?.message));
      return false;
    } catch (error) {
      return false;
    }
  };
};

const actionLogin = (data: { value: string }) => {
  return async (dispatch: any) => {
    try {
      dispatch(begin({ loading: true }));
      const response = await api.post("/users/send-login-code", data);
      if (response.status >= 200 && response.status <= 201) {
        dispatch(begin({ loading: false }));
        return true;
      }

      dispatch(error(response?.data?.message));
      return false;
    } catch (error) {
      return false;
    }
  };
};

const actionValidCode = (data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(begin({ loading: true }));
      const response = await api.post("/users/validate-code", data);
      if (response.status >= 200 && response.status <= 201) {
        dispatch(begin({ loading: false }));
        const { data } = response;

        const payload = {
          data: {
            token: data.data.token,
            expiresIn: 0,
            authState: data.data.user,
          },
        };

        const decoded: any = jwt_decode(payload.data.token);
        payload.data.expiresIn = decoded.exp;

        return payload;
      }

      dispatch(error(response?.data?.message));
      return false;
    } catch (error) {
      return false;
    }
  };
};

export { begin, actionLoginSuper, actionLogin, actionValidCode, checkUserRole };
