import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Col,
  Collapse,
  Descriptions,
  Divider,
  Form,
  InputNumber,
  Modal,
  Row,
  Select,
  Spin,
  Typography,
  Radio,
} from "antd";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { EditText } from "react-edit-text";

import {
  addVersionBalcony,
  saveBalconyMeasurement,
  updateBalcony,
  getBalconyTypes,
  deleteBalcony,
  getBalconyVersion,
  saveBalcony,
  getProjectById,
  deleteFloor,
} from "../../../redux/projects/actions";
import BalconyWithMeasurements from "../components/balconyWithMeasurements";
import BalconyClone from "./balconyClone";
import { getDateString } from "../../../utils/getFormats";
import BalconyRow from "./balconyRow";
import VersionSelect from "./versionSelect";
import BalconyTypeSelect from "./balconyTypeSelect";
import { getUsers } from "../../../redux/users/actions";
import { useAuthUser } from "react-auth-kit";
import { ROLES } from "../../../utils/roles";
import { useNavigate, useParams } from "react-router-dom";

const { Panel } = Collapse;
const { Title, Paragraph } = Typography;

/* eslint-disable react-hooks/exhaustive-deps */
const Measurements = () => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [formEdit] = Form.useForm();
  const floor = Form.useWatch("floorId", form);

  const [balconyTypes, setBalconyTypes] = useState<any>([]);
  const [balconyMeasurements, setBalconyMeasurements] = useState<any>([]);
  const [visibleForm, setVisibleForm] = useState(false);
  const [visibleFormEdit, setVisibleFormEdit] = useState(false);
  const [visibleReadInfo, setVisibleReadInfo] = useState(false);
  const [visiblePlan, setVisiblePlan] = useState(false);
  const [visibleClone, setVisibleClone] = useState(false);

  const [balconyToClone, setBalconyToClone] = useState<any>({});
  const [balconiesOfProject, setBalconiesOfProject] = useState<any>([]);
  const [balconyVersionSelected, setBalconyVersionSelected] = useState<any>();
  const [balconyVersions, setBalconyVersions] = useState<any>();
  const [balconyTypeSelected, setBalconyTypeSelected] = useState<any>({});
  const [currentVersion, setCurrentVersion] = useState<any>();
  const { floors } = useAppSelector((state) => state.projectsReducer.project);
  const project = useAppSelector((state) => state.projectsReducer.project);
  const authUser = useAuthUser();
  const navigate = useNavigate();
  const user = authUser();
  const { id, projectVersion: version } = useParams();

  const loaderFloors = useAppSelector(
    (state) => state.projectsReducer.loaderFloors
  );
  const loaderBalconies = useAppSelector(
    (state) => state.projectsReducer.loaderBalconies
  );
  const totalBalconies = floors?.reduce(
    (acc: number, el: any) => acc + el.balconies.length,
    0
  );

  const group_id = useAppSelector(
    (state) => state.projectsReducer.project.group_id
  );

  useEffect(() => {
    getCatalog();
    setCurrentVersion(project.actually_version);
    if (
      user?.role === ROLES.ROLE_SUPER_ADMIN ||
      user?.role === ROLES.ROLE_ADMIN_USER
    ) {
      getUsersList();
    }
  }, []);

  useEffect(() => {
    form.setFieldValue(
      "balconies",
      floors
        ?.find((el: any) => el.id === floor)
        ?.balconies?.map((el: any) => {
          return el.id;
        })
    );
  }, [floor]);

  const getCatalog = async () => {
    const data = await dispatch(getBalconyTypes(1, 100));
    if (data) setBalconyTypes(data);
  };

  const handleSaveBalconyMeasurement = async (data: any) => {
    const status: any = await dispatch(
      saveBalconyMeasurement(data, balconyMeasurements?.id, currentVersion)
    );
    if (status >= 200 && status <= 201) {
      dispatch(getProjectById(project.id, { version: currentVersion }));
      handleCancelEdit();
    }
  };

  const handleAddVersion = async (data: any) => {
    await dispatch(addVersionBalcony(data.floorId));
    handleCancel();
  };

  const handleCancel = () => {
    form.resetFields();
    setVisibleForm(false);
  };

  const handleCancelEdit = () => {
    formEdit.resetFields();
    setBalconyMeasurements([]);
    setVisibleFormEdit(false);
    setVisibleReadInfo(false);
  };

  const buildInfo = (data: any) => {
    if (data)
      return Object.entries(data).map(([key, value], i) => {
        return (
          <Descriptions
            key={i}
            bordered
            column={1}
            labelStyle={{ maxWidth: "130px" }}
            contentStyle={{ textAlign: "center" }}
            style={{ paddingTop: "20px" }}
            title={key.replaceAll("_", " ")?.toUpperCase()}
          >
            {Object.entries(data?.[key]).map(([subkey, subvalue], subi) => {
              return (
                <Descriptions.Item
                  key={subi}
                  label={subkey.replaceAll("_", " ")}
                >
                  {data?.[key]?.[subkey]}
                </Descriptions.Item>
              );
            })}
          </Descriptions>
        );
      });
  };
  const onCloneBalcony = (balcony: any) => {
    setBalconyToClone(balcony);
    const balconies = floors;
    setBalconiesOfProject(balconies);
    setVisibleClone(true);
  };
  const onDeleteBalcony = (balcony: any) => {
    dispatch(deleteBalcony(balcony.id));
  };

  const genExtra = (floor: any) => (
    <FontAwesomeIcon
      icon="trash-alt"
      onClick={(event: any) => {
        event.stopPropagation();
        onDeleteFloor(floor.id);
      }}
    />
  );
  const onEditBalcony = (balcony: any) => {
    formEdit.setFieldsValue(balcony?.measurements?.input);
    setBalconyMeasurements(balcony);
    setCurrentVersion(project.actually_version);
    setVisibleFormEdit(true);
  };

  const onBalconyMeasurements = (balcony: any) => {
    setBalconyVersionSelected(balcony.version);
    setBalconyMeasurements(balcony);
    setBalconyVersions(balcony?.versions);
    setVisibleReadInfo(true);
  };

  const onChangeBalconyVersion = async (version: any) => {
    setBalconyVersionSelected(version);
    /*  const balconyOnVersion = await 
    ); */
    setBalconyMeasurements(
      await dispatch(
        getBalconyVersion(balconyMeasurements?.id, { version: version })
      )
    );
  };

  const onCreateBalcony = (value: any, floor: any) => {
    dispatch(
      saveBalcony({
        floor_id: floor,
        quantity: parseInt(value),
      })
    );
  };

  const onChangeVersion = (value: any) => {
    setCurrentVersion(value);
    /* edit url version */
    navigate(`/dashboard/projects/${project.id}/version/${value}`);

    dispatch(getProjectById(project.id, { version: value }));
  };

  const addNewVersion = async (form: any) => {
    await dispatch(addVersionBalcony(project.id, form));
    const newVersion = Number(project.actually_version) + 1;
    navigate(`/dashboard/projects/${project.id}/version/${newVersion}`);

    handleCancel();
    setCurrentVersion(project.actually_version);
  };

  const getUsersList = (page = 1) => {
    dispatch(getUsers(page, group_id));
  };

  const onDeleteFloor = (floorId: number) => {
    dispatch(deleteFloor(floorId));
  };

  const getProjectVersion = () => {
    /* change to int  */

    return Number(project?.actually_version);
  };
  return (
    <Spin spinning={loaderFloors}>
      <div
        style={{
          textAlign: "right",
          marginRight: "30px",
          position: "sticky",
          top: "3rem",
          zIndex: 1,
        }}
      >
        <Row justify="end">
          {project.actually_version && (
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <VersionSelect
                versions={project.versions}
                currentVersion={getProjectVersion()}
                changeVersion={onChangeVersion}
              />
            </Col>
          )}
          {(user?.role === ROLES.ROLE_SUPER_ADMIN ||
            user?.role === ROLES.ROLE_ADMIN_USER) && (
            <Col xs={24} sm={24} md={4} lg={4} xl={4}>
              <Button
                onClick={() => {
                  getUsersList();
                  setVisibleForm(true);
                }}
                className="btn-default-allunox"
                type="default"
                disabled={loaderFloors}
              >
                Add New Version
              </Button>
            </Col>
          )}
        </Row>
      </div>
      <Collapse
        className="accordeon-allunox"
        defaultActiveKey={["0"]}
        bordered={false}
        accordion
        ghost
      >
        {floors?.map((el: any, i: number) => (
          <Panel
            className={
              i === 0 ? "first" : i === floors.length - 1 ? "last" : ""
            }
            showArrow={false}
            header={
              <>
                <Row justify="start">
                  <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                    <Title style={{ marginTop: 0 }} level={3}>
                      <div className="inline ">{el.name}</div>
                    </Title>
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                    <Title style={{ marginTop: 0 }} level={3}>
                      Balconies:{" "}
                      <div className="inline">
                        <EditText
                          placeholder="Balconies"
                          defaultValue={el.balconies.length || 0}
                          inputClassName="p-0 m-0"
                          className="p-0 m-0"
                          showEditButton
                          onSave={(value) =>
                            onCreateBalcony(value.value, el.id)
                          }
                          readonly={
                            user?.role !== ROLES.ROLE_SUPER_ADMIN &&
                            user?.role !== ROLES.ROLE_ADMIN_USER
                          }
                        />
                      </div>
                    </Title>

                    <div className="inline"></div>
                  </Col>
                </Row>

                {/*       <Paragraph>
                  No. Balconies {el?.balconies?.length}
                  <b style={{ marginLeft: "20px" }}>
                    Progress 0/{el?.balconies.length}
                  </b>
                </Paragraph> */}
              </>
            }
            extra={
              user?.role === ROLES.ROLE_SUPER_ADMIN ||
              user?.role === ROLES.ROLE_ADMIN_USER
                ? genExtra(el)
                : null
            }
            key={i}
          >
            {/* <Row>
              {" "}
              <Col xs={4} sm={4} md={4} lg={2} xl={2}></Col>
              <Col xs={20} sm={20} md={20} lg={4} xl={4}></Col>
              <Col xs={20} sm={20} md={20} lg={4} xl={4}>
                <strong>Parent ID</strong>
              </Col>{" "}
            </Row> */}

            {el?.balconies?.map((balcony: any, i: number) => {
              return (
                <React.Fragment key={i}>
                  <BalconyRow
                    balcony={balcony}
                    onCloneBalcony={onCloneBalcony}
                    onEditBalcony={onEditBalcony}
                    onDeleteBalcony={onDeleteBalcony}
                    onBalconyMeasurements={onBalconyMeasurements}
                    style={{
                      backgroundColor: i % 2 === 0 ? "white" : "#dfdddd",
                    }}
                  />
                </React.Fragment>
              );
            })}
            <br />
          </Panel>
        ))}
      </Collapse>

      <Modal
        title={<>Add New Version</>}
        open={visibleForm}
        footer={<></>}
        onCancel={handleCancel}
      >
        <div style={{ marginTop: 30 }}>
          <Form
            className="form-allunox"
            name="version"
            layout="vertical"
            form={form}
            onFinish={addNewVersion}
          >
            <Form.Item
              style={{ marginBottom: "39px" }}
              label="Measurement Brick"
              name="measurement_brick"
              rules={[{ required: true, message: "This field is required" }]}
            >
              <Radio.Group>
                <Radio value={"before"}>Before</Radio>
                <Radio value={"after"}>After</Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item style={{ textAlign: "right" }}>
              <br />
              <Button size="large" htmlType="submit" type="primary">
                Save
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>

      <Modal
        title={<></>}
        open={visibleFormEdit}
        centered
        footer={<></>}
        onCancel={handleCancelEdit}
        width="500px"
      >
        <Spin spinning={loaderBalconies}>
          <div style={{ marginTop: 30 }}>
            <Form
              className="form-allunox"
              name="version"
              layout="vertical"
              form={formEdit}
              onFinish={handleSaveBalconyMeasurement}
            >
              <Form.Item
                style={{ textAlign: "center" }}
                label="Balcony Type"
                name="balcony_type_id"
                rules={[{ required: true, message: "This field is required" }]}
              >
                <BalconyTypeSelect
                  balconyType={
                    balconyMeasurements?.measurements?.balcony_type_id || 0
                  }
                  onBalconyTypeChange={(value: any) => {
                    setBalconyTypeSelected(
                      balconyTypes?.find((el: any) => el.id === value) ?? {}
                    );
                    formEdit.setFieldsValue({
                      ...formEdit.getFieldsValue(),
                      balcony_type_id: value,
                    });
                  }}
                />
              </Form.Item>

              {Object.entries(balconyTypeSelected?.requires ?? {}).map(
                ([key, value]) => (
                  <Form.Item
                    key={key}
                    style={{ marginBottom: "39px" }}
                    label={`Measurement ${value}`}
                    name={`${key}`}
                    rules={[
                      { required: true, message: "This field is required" },
                    ]}
                  >
                    <InputNumber />
                  </Form.Item>
                )
              )}
              <Form.Item style={{ textAlign: "right" }}>
                <Button
                  disabled={loaderBalconies}
                  size="large"
                  htmlType="submit"
                  type="primary"
                >
                  Save
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Spin>
      </Modal>

      <Modal
        title={"Balcony Measurements"}
        open={visibleReadInfo}
        centered
        footer={<></>}
        onCancel={handleCancelEdit}
        width="700px"
      >
        <div style={{ marginTop: 30 }}>
          <Descriptions bordered column={1} labelStyle={{ maxWidth: "80px" }}>
            <Descriptions.Item label="Balcony Type">
              {
                balconyTypes?.find(
                  (el: any) =>
                    el?.id ===
                    balconyMeasurements?.measurements?.balcony_type_id
                )?.name
              }
            </Descriptions.Item>
          </Descriptions>

          <Row gutter={24}>
            <Col span={24} sm={24}>
              <Divider orientation="center">
                {balconyMeasurements?.measurements?.measurement_before_brick
                  ? "Before"
                  : "After"}{" "}
                Brick
              </Divider>
              {buildInfo(balconyMeasurements?.measurements?.output_fraction)}
            </Col>
          </Row>
        </div>
      </Modal>

      <Modal
        title={"Balcony"}
        open={visiblePlan}
        centered
        destroyOnClose
        footer={<></>}
        onCancel={() => {
          setVisiblePlan(false);
        }}
        width="1200px"
      >
        <div style={{ textAlign: "center", marginTop: 20 }}>
          <BalconyWithMeasurements
            balconyMeasurements={balconyMeasurements?.measurements?.output}
          />
        </div>
      </Modal>

      <Modal
        title={"Clone balcony"}
        open={visibleClone}
        centered
        destroyOnClose
        footer={<></>}
        onCancel={() => {
          setVisibleClone(false);
        }}
        width="500px"
      >
        <div style={{ textAlign: "center", marginTop: 20 }}>
          <BalconyClone
            balconyToClone={balconyToClone}
            balconiesOfProject={balconiesOfProject}
            onCloseModal={() => setVisibleClone(false)}
            currentProjectVersion={currentVersion}
          />
        </div>
      </Modal>
    </Spin>
  );
};

export default Measurements;
