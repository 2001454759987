import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row, Spin } from "antd";
import { getPDF } from "../../../utils/buildPDF";

/* eslint-disable react-hooks/exhaustive-deps */
const BalconyWithMeasurements = ({ balconyMeasurements }: any) => {
  const [loaderPlan, setLoaderVisiblePlan] = useState(false);
  const [pdfInfo, setPdfInfo] = useState<any>([]);
  const [layers, setLayers] = useState<any>({
    1: { active: true, label: "Glass" },
    2: { active: true, label: "Center to Center " },
    3: { active: true, label: "Balcony Measurements" },
    4: { active: true, label: "Railing /wall Measurement" },
  });

  useEffect(() => {
    setData();
  }, [layers]);

  const setData = async () => {
    setLoaderVisiblePlan(true);
    setPdfInfo(await getPDF(1, layers, balconyMeasurements));
    setLoaderVisiblePlan(false);
  };

  return (
    <Row>
      <Col sm={6}>
        <div style={{ marginBottom: 10, textAlign: "start" }}>
          {Object.keys(layers).map((layer, i) => {
            return (
              <div
                key={i}
                onClick={() => {
                  if (!loaderPlan) {
                    layers[layer].active = !layers[layer].active;

                    setLayers({ ...layers });
                  }
                }}
                style={{
                  margin: "5px 20px",
                  fontSize: 16,
                  cursor: "pointer",
                }}
              >
                <FontAwesomeIcon
                  style={{ paddingRight: 5 }}
                  icon={layers[layer].active ? "square-check" : "square"}
                />
                {layers[layer].label}
              </div>
            );
          })}
        </div>
      </Col>
      <Col sm={18}>
        <Spin spinning={loaderPlan}>
          {
            <iframe
              style={{ minHeight: "900px", height: "100%" }}
              title="frame"
              src={pdfInfo ? pdfInfo : undefined}
              height="100%"
              width="100%"
            />
          }
        </Spin>
      </Col>
    </Row>
  );
};

export default BalconyWithMeasurements;
