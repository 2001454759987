import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Form, Image, Modal, Popconfirm, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  getSides,
  getSideById,
  updateSide,
  saveSide,
  cleanSide,
} from "../../redux/sides/actions";
import SidesForm from "./sidesForm";

/* eslint-disable react-hooks/exhaustive-deps */
const Sides = () => {
  const [visibleForm, setVisibleForm] = useState(false);

  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const side = useAppSelector((state) => state.sidesReducer.side);
  const sides = useAppSelector((state) => state.sidesReducer.sides);
  const loaderSides = useAppSelector((state) => state.sidesReducer.loaderSides);

  useEffect(() => {
    getData(sides?.current_page || 1);
  }, []);

  useEffect(() => {
    if (side.id) {
      form.setFieldsValue(side);
    }
  }, [side]);

  const getData = (page = 1) => {
    dispatch(getSides(page));
  };

  const showModalForm = async (id: number) => {
    if (id) await dispatch(getSideById(id));

    setVisibleForm(true);
  };

  const actionsMarkup = (id: number) => {
    return (
      <div className="table-actions">
        <>
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => dispatch(updateSide({ status: 2 }, id))}
          >
            <Button type="text">
              <FontAwesomeIcon icon="trash-alt" />
            </Button>
          </Popconfirm>
          <Button
            className="color-primary"
            onClick={() => showModalForm(id)}
            type="text"
          >
            <FontAwesomeIcon icon="edit" />
          </Button>
        </>
      </div>
    );
  };

  const colums: ColumnsType<any> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },

    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
    },
  ];

  const data = sides?.data?.map((el: any) => {
    const { id, name } = el;
    return {
      key: id,
      id,
      name,
      actions: actionsMarkup(id),
    };
  });

  const onFinish = (data: any) => {
    if (sides?.id) {
      dispatch(updateSide(data, sides?.id));
    } else {
      dispatch(saveSide(data));
    }

    handleCancel();
  };

  const handleCancel = () => {
    dispatch(cleanSide());
    form.resetFields();
    setVisibleForm(false);
  };

  return (
    <Card title="Sides">
      <div style={{ textAlign: "end" }}>
        <Button
          onClick={() => {
            setVisibleForm(true);
          }}
          type="primary"
        >
          Add Side
        </Button>
        <br />
        <br />
      </div>
      <div className="table-responsive">
        <Table
          bordered
          loading={loaderSides}
          columns={colums}
          dataSource={data}
          pagination={{
            current: sides?.current_page,
            pageSize: sides?.per_page || 0,
            hideOnSinglePage: true,
            total: sides?.total,
            showSizeChanger: false,
            showTotal: (total: number, range: any[]) =>
              `${range[0]}-${range[1]} of ${total} items`,
            onChange: getData,
          }}
        />
      </div>

      <Modal
        title={<>Update Sides</>}
        open={visibleForm}
        footer={<></>}
        destroyOnClose
        onCancel={handleCancel}
      >
        <div style={{ textAlign: "center" }}>
          <br />
          <Image
            preview={false}
            style={{ width: "100%" }}
            src={require("../../static/img/footer-logo.png")}
            alt="..."
          />
          <br />
          <br />
        </div>

        <SidesForm form={form} onFinish={onFinish} onCancel={handleCancel} />
      </Modal>
    </Card>
  );
};

export default Sides;
