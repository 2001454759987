import { actions } from "./actions";
const { ACTION_BEGIN, ERROR, SUCCESS_USER } = actions;

const initialState = {
  loading: false,
};

export default function responseReducer(state = initialState, action: any) {
  const { type, data, err } = action;

  switch (type) {
    case ACTION_BEGIN:
      const stateClone = { ...state, ...data };
      return {
        ...stateClone,
      };
    case ERROR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    case SUCCESS_USER:
      return {
        ...state,
        loading: false,
        user: data,
      };
    default:
      return state;
  }
}
