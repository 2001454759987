import api from "../../api";

export const actions = {
  ACTION_BEGIN: "ACTION_BEGIN",
  ERROR: "ERROR",
  SUCCESS_USER: "SUCCESS_USER",
};

const begin = (payload: any) => {
  return (dispatch: any) => {
    dispatch({
      type: actions.ACTION_BEGIN,
      payload,
    });
  };
};

const error = (err: any) => {
  return { type: actions.ERROR, err };
};

const updateProfile = (data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(begin({ loader: true }));

      const response = await api.put("/profile", data);

      if (response.status >= 200 && response.status <= 201) {
        const { data } = response;

        dispatch({
          type: actions.SUCCESS_USER,
          data: data.data,
        });

        return;
      }

      dispatch(error(response?.data?.message));
      return response?.data?.message;
    } catch (err) {
      dispatch(error(err));
      return err;
    }
  };
};
export { begin, updateProfile };
