import { actions } from "./actions";
const {
  ACTION_BEGIN,
  ERROR,
  SUCCESS_ROLES,
  SUCCESS_USERS,
  SUCCESS_USER,
  CLEAN_USER,
} = actions;

const initialState = {
  loading: false,
  loaderUsers: false,
  roles: [],
  users: {},
  user: {},
};

export default function responseReducer(state = initialState, action: any) {
  const { type, data, err } = action;

  switch (type) {
    case ACTION_BEGIN:
      const stateClone = { ...state, ...data };
      return {
        ...stateClone,
      };
    case SUCCESS_ROLES:
      return {
        ...state,
        roles: data,
      };
    case SUCCESS_USERS:
      return {
        ...state,
        users: data,
      };
    case SUCCESS_USER:
      return {
        ...state,
        user: data,
      };

    case CLEAN_USER:
      return {
        ...state,
        user: {},
      };
    case ERROR:
      return {
        ...state,
        loading: false,
        loaderUsers: false,
        error: err,
      };
    default:
      return state;
  }
}
