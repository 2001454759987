import { actions } from "./actions";
const {
  ACTION_BEGIN,
  ERROR,
  SUCCESS_ROLES,
  SUCCESS_GROUP,
  SUCCESS_GROUPS,
  CLEAN_GROUP,
} = actions;

const initialState = {
  loading: false,
  loaderGroups: false,
  roles: [],
  groups: [],
  group: {},
};

export default function responseReducer(state = initialState, action: any) {
  const { type, data, err } = action;

  switch (type) {
    case ACTION_BEGIN:
      const stateClone = { ...state, ...data };
      return {
        ...stateClone,
      };
    case SUCCESS_ROLES:
      return {
        ...state,
        roles: data,
      };
    case SUCCESS_GROUPS:
      return {
        ...state,
        groups: data,
      };
    case SUCCESS_GROUP:
      return {
        ...state,
        group: data,
      };

    case CLEAN_GROUP:
      return {
        ...state,
        group: {},
      };
    case ERROR:
      return {
        ...state,
        loading: false,
        loaderUsers: false,
        error: err,
      };
    default:
      return state;
  }
}
