import api from "../../api";

export const actions = {
  SUCCESS_BALCONYTYPE: "SUCCESS_BALCONYTYPE",
  SUCCESS_BALCONYTYPES: "SUCCESS_BALCONYTYPES",
  CLEAN_BALCONYTYPE: "CLEAN_BALCONYTYPE",
  ACTION_BEGIN: "ACTION_BEGIN",
  ERROR: "ERROR",
};

const path = "/balcony-type";

const begin = (data: any) => {
  return (dispatch: any) => {
    dispatch({
      type: actions.ACTION_BEGIN,
      data,
    });
  };
};

const error = (err: any) => {
  return { type: actions.ERROR, err };
};

const getBalconyTypes = (page?: number) => {
  return async (dispatch: any) => {
    try {
      dispatch(begin({ loaderBalconyTypes: true }));
      const response = await api.get(`${path}${page ? "?page=" + page : ""}`);
      dispatch(begin({ loaderBalconyTypes: false }));

      if (response.status >= 200 && response.status <= 201) {
        const { data } = response;
        dispatch({
          type: actions.SUCCESS_BALCONYTYPES,
          data: data.data,
        });
        return;
      }

      dispatch(error(response?.data?.message));
      return response?.data?.message;
    } catch (err) {
      console.log("error", err);
      dispatch(error(err));
      return err;
    }
  };
};

const saveBalconyType = (data: any) => {
  return async (dispatch: any) => {
    try {
      const response = await api.post(`${path}`, data);
      if (response.status >= 200 && response.status <= 201) {
        dispatch(getBalconyTypes());
        return;
      }

      dispatch(error(response?.data?.message));
      return response?.data?.message;
    } catch (err) {
      dispatch(error(err));
      return err;
    }
  };
};

const updateBalconyType = (data: any, id: number) => {
  return async (dispatch: any) => {
    try {
      const response = await api.put(`${path}/${id}`, data);
      if (response.status >= 200 && response.status <= 201) {
        dispatch(getBalconyTypes());
        return;
      }

      dispatch(error(response?.data?.message));
      return response?.data?.message;
    } catch (err) {
      dispatch(error(err));
      return err;
    }
  };
};

const deleteBalconyType = (id: number) => {
  return async (dispatch: any) => {
    try {
      const response = await api.delete(`${path}/${id}`);
      if (response.status >= 200 && response.status <= 201) {
        dispatch(getBalconyTypes());
        return;
      }

      dispatch(error(response?.data?.message));
      return response?.data?.message;
    } catch (err) {
      dispatch(error(err));
      return err;
    }
  };
};

const getBalconyTypeById = (id: number) => {
  return async (dispatch: any) => {
    try {
      const response = await api.get(`${path}/${id}`);
      if (response.status >= 200 && response.status <= 201) {
        const { data } = response;

        dispatch({
          type: actions.SUCCESS_BALCONYTYPE,
          data: data.data,
        });
        return;
      }

      dispatch(error(response?.data?.message));
      return response?.data?.message;
    } catch (err) {
      dispatch(error(err));
      return err;
    }
  };
};

const cleanBalconyType = () => {
  return async (dispatch: any) => {
    dispatch({ type: actions.CLEAN_BALCONYTYPE });
  };
};

export {
  begin,
  getBalconyTypes,
  saveBalconyType,
  updateBalconyType,
  getBalconyTypeById,
  deleteBalconyType,
  cleanBalconyType,
};
