import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Layout, Menu, Button, Typography } from "antd";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useSignOut, useAuthUser, useIsAuthenticated } from "react-auth-kit";
import useGetItems from "./menu/adminMenu";
import { ROLES_LABEL } from "../utils/roles";
const { Text } = Typography;
const { Header, Content, Sider } = Layout;

/* eslint-disable react-hooks/exhaustive-deps */
const WithAdminLayout: React.FC = () => {
  const signOut = useSignOut();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const authUser = useAuthUser();
  const user = authUser();
  const getItem = useGetItems();
  const isAuthenticated = useIsAuthenticated();
  const [active, setActive] = useState<any>("");
  const [items, setItems] = useState<any>([]);
  const auth = isAuthenticated();

  useEffect(() => {
    if (!auth) {
      setItems([]);
    } else {
      setItems(getItem);
    }
  }, [auth]);

  useEffect(() => {
    const text = pathname;
    const word = "/dashboard/";

    const array = text.split(word);
    const result = array.pop();
    setActive(result);
  }, [pathname]);

  return (
    <Layout className="admin">
      <Sider
        width={250}
        className="leftNav"
        collapsedWidth="0"
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        trigger={
          <Button
            type="text"
            className="trigger text-white"
            onClick={() => setCollapsed(!collapsed)}
            icon={
              collapsed ? (
                <FontAwesomeIcon icon="chevron-right" />
              ) : (
                <FontAwesomeIcon icon="chevron-left" />
              )
            }
          />
        }
      >
        <div
          style={{
            height: 32,
            margin: 16,
            paddingBottom: "7rem",
            marginTop: "45px",
          }}
        >
          <img
            style={{ width: "100%" }}
            src={require("../static/img/logo-white.png")}
            alt="..."
          />
        </div>
        <Menu
          onClick={(el) => navigate(el.key)}
          className="leftNav"
          theme="dark"
          mode="inline"
          selectedKeys={[active]}
          items={items}
        />
      </Sider>
      <Layout className="site-layout">
        <Header style={{ padding: 0, background: "#032259" }}>
          <img
            className="logo-mobile"
            src={require("../static/img/logo-white.png")}
            alt="..."
          />

          <div className="actions">
            <label className="text-name text-white">
              <Text
                ellipsis={{
                  tooltip: `${user?.user_detail?.first_name}  ${
                    user?.user_detail?.last_name || ""
                  }`,
                }}
                style={{
                  textAlign: "right",
                  width: "160px",
                  color: "white",
                  display: "table-caption",
                  marginBottom: "-10px",
                  marginRight: 10,
                }}
              >
                {`${user?.user_detail?.first_name}  ${
                  user?.user_detail?.last_name || ""
                }`}
                <br />
                {ROLES_LABEL[user?.role]}
              </Text>

              <FontAwesomeIcon icon="user" size="lg" fixedWidth />
            </label>
            <Button
              onClick={() => navigate("profile")}
              className="text-white"
              type="text"
              icon={<FontAwesomeIcon icon="gear" size="lg" />}
            />
            <Button
              className="text-white"
              type="text"
              icon={<FontAwesomeIcon icon="right-from-bracket" size="lg" />}
              onClick={() => {
                if (signOut()) navigate("/");
              }}
            />
          </div>
        </Header>

        <Content>
          <div className="container-admin">
            <Outlet />
          </div>
        </Content>

        {/**<Footer style={{ textAlign: "center" }}></Footer>*/}
      </Layout>
    </Layout>
  );
};

export default WithAdminLayout;
