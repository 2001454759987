import { degrees, PDFDocument, PDFPage, rgb } from "pdf-lib";

export const getPDF = async (
  type: number,
  layers: any,
  balconyMeasurements?: any,
  projectData?: any,
  setLoadProcess = (_data: any) => {}
) => {
  const pdfDoc = await PDFDocument.create();
  switch (type) {
    case 1: {
      if (balconyMeasurements) {
        await balconyType1(layers, balconyMeasurements, pdfDoc);
      }
      if (projectData) {
        let processCount = 0;
        for (let i = 0; i < projectData?.floors?.length; i++) {
          const floor = projectData?.floors[i];

          for (let j = 0; j < floor?.balconies?.length; j++) {
            const balcony = floor?.balconies[i];
            processCount = processCount + 1;

            const page: PDFPage = await balconyType1(
              layers,
              balcony?.measurements[balcony?.measurements.output - 1],
              pdfDoc
            );

            setLoadProcess({
              total: projectData?.floors?.reduce(
                (a: any, b: any) => a + b.balconies?.length,
                0
              ),
              process: processCount,
            });

            page.drawText(floor?.name, { size: 25, y: 750 });
            page.drawText(
              `${balcony?.name} (v${balcony?.balcony_measurements?.length})`,
              {
                size: 15,
                y: 735,
              }
            );
          }
        }
      }

      const params = {
        autoOptimize: true,
        useObjectStreams: true,
        updateMetadata: false,
      };

      const pdfBytes = await pdfDoc.save(params);
      const bytes = new Uint8Array(pdfBytes);
      const blob = new Blob([bytes], { type: "application/pdf" });
      const docUrl = URL.createObjectURL(blob);
      return docUrl;
    }

    default:
  }
};

const balconies = {
  1: "balcony_1",
};

const castRGB = (data1: any, data2: any, data3: any) => {
  return rgb(data1 / 255, data2 / 255, data3 / 255);
};

const balconyType1 = async (
  layers: any,
  balconyMeasurements: any,
  pdfDoc: PDFDocument
) => {
  /*// Fetch an existing PDF document
  const url = `${window.location.origin}/pdfs/${balconies[1]}/balcony_base.pdf`;
  const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());

  const pdfDoc = await PDFDocument.load(existingPdfBytes);
  const pages = pdfDoc.getPages();
  const firstPage = pages[0];

  // Get the width and height of the first page
  const { height } = firstPage.getSize();*/

  const page = pdfDoc.addPage();
  page.setHeight(792);
  page.setWidth(612);

  const { height } = page.getSize();

  let after_brick = balconyMeasurements?.after_brick;
  if (!after_brick) after_brick = {};

  const urlBase = `${window.location.origin}/pdfs/${balconies[1]}/base.png`;
  const basePngBytes = await fetch(urlBase).then((res) => res.arrayBuffer());
  const base = await pdfDoc.embedPng(basePngBytes);
  const baseDims = base.scale(0.36);
  page.drawImage(base, { ...baseDims, y: 0, x: 0 });

  if (layers[1].active) {
    const urlGlass = `${window.location.origin}/pdfs/${balconies[1]}/glass.png`;
    const glassPngBytes = await fetch(urlGlass).then((res) =>
      res.arrayBuffer()
    );
    const glass = await pdfDoc.embedPng(glassPngBytes);
    const glassDims = glass.scale(0.36);
    page.drawImage(glass, { ...glassDims, y: 0, x: 0 });

    [
      {
        name: "glass_size",
        side: "front",
        x: 475,
        y: 140,
        rotate: 270,
        size: 9,
      },
      {
        name: "glass_size",
        side: "front",
        x: 473,
        y: 54,
        rotate: 270,
        size: 9,
      },
      {
        name: "glass_size",
        side: "front",
        x: 475,
        y: -33,
        rotate: 270,
        size: 9,
      },

      {
        name: "glass_size",
        side: "side_a",
        x: 260,
        y: 275,
        rotate: 0,
        size: 9,
      },
      {
        name: "glass_size",
        side: "side_a",
        x: 320,
        y: 275,
        rotate: 0,
        size: 9,
      },
      {
        name: "glass_size",
        side: "side_b",
        x: 260,
        y: -208,
        rotate: 0,
        size: 9,
      },
      {
        name: "glass_size",
        side: "side_b",
        x: 320,
        y: -208,
        rotate: 0,
        size: 9,
      },
    ].forEach((el) => {
      page.drawText(
        after_brick?.[el.side]?.[el.name]?.toFixed(2)?.toString() || "",
        {
          x: el.x,
          y: height / 2 + el.y,
          size: el.size,
          rotate: degrees(el.rotate),
          color: castRGB(224, 224, 224),
        }
      );
    });
  }

  if (layers[2].active) {
    const urlCenterToCenter = `${window.location.origin}/pdfs/${balconies[1]}/center_to_center.png`;
    const centerToCenterPngBytes = await fetch(urlCenterToCenter).then((res) =>
      res.arrayBuffer()
    );
    const centerToCenter = await pdfDoc.embedPng(centerToCenterPngBytes);
    const centerToCenterDims = centerToCenter.scale(0.36);
    page.drawImage(centerToCenter, {
      ...centerToCenterDims,
      y: 0,
      x: 0,
    });

    [
      {
        name: "center_to_center",
        side: "side_a",
        x: 270,
        y: 103,
        rotate: 0,
        size: 9,
      },
      {
        name: "center_to_center",
        side: "front",
        x: 304,
        y: 52,
        rotate: 270,
        size: 9,
      },
      {
        name: "center_to_center",
        side: "side_b",
        x: 270,
        y: -45,
        rotate: 0,
        size: 9,
      },
    ].forEach((el) => {
      page.drawText(
        after_brick?.[el.side]?.[el.name]?.toFixed(2)?.toString() || "",
        {
          x: el.x,
          y: height / 2 + el.y,
          size: el.size,
          rotate: degrees(el.rotate),
          color: castRGB(0, 0, 0),
        }
      );
    });
  }

  if (layers[3].active) {
    const urlBalcony = `${window.location.origin}/pdfs/${balconies[1]}/balcony.png`;
    const balconyPngBytes = await fetch(urlBalcony).then((res) =>
      res.arrayBuffer()
    );
    const balcony = await pdfDoc.embedPng(balconyPngBytes);
    const balconyDims = balcony.scale(0.36);
    page.drawImage(balcony, {
      ...balconyDims,
      y: 0,
      x: 0,
    });

    [
      {
        name: "balcony_measurement",
        side: "front",
        x: 427,
        y: 65,
        rotate: 270,
        size: 11,
      },
      {
        name: "balcony_measurement",
        side: "side_a",
        x: 280,
        y: 227,
        rotate: 0,
        size: 11,
      },
      {
        name: "balcony_measurement",
        side: "side_b",
        x: 287,
        y: -172,
        rotate: 0,
        size: 11,
      },
    ].forEach((el) => {
      page.drawText(
        after_brick?.[el.side]?.[el.name]?.toFixed(2)?.toString() || "",
        {
          x: el.x,
          y: height / 2 + el.y,
          size: el.size,
          rotate: degrees(el.rotate),
          color: castRGB(0, 0, 0),
        }
      );
    });
  }

  if (layers[4].active) {
    const urlRailingWall = `${window.location.origin}/pdfs/${balconies[1]}/railing_wall.png`;
    const railingWallBytes = await fetch(urlRailingWall).then((res) =>
      res.arrayBuffer()
    );
    const railingWall = await pdfDoc.embedPng(railingWallBytes);
    const railingWallDims = railingWall.scale(0.36);
    page.drawImage(railingWall, {
      ...railingWallDims,
      y: 0,
      x: 0,
    });

    [
      {
        name: "railing_measurement",
        side: "front",
        x: 390,
        y: 65,
        rotate: 270,
        size: 11,
      },
      {
        name: "railing_measurement",
        side: "side_a",
        x: 280,
        y: 202,
        rotate: 0,
        size: 11,
      },
      {
        name: "railing_measurement",
        side: "side_b",
        x: 277,
        y: -138,
        rotate: 0,
        size: 11,
      },
    ].forEach((el) => {
      page.drawText(
        after_brick?.[el.side]?.[el.name]?.toFixed(2)?.toString() || "",
        {
          x: el.x,
          y: height / 2 + el.y,
          size: el.size,
          rotate: degrees(el.rotate),
          color: castRGB(0, 0, 0),
        }
      );
    });
  }

  return page;
};
