import { Button, Card, Col, Divider, Form, Input, Radio, Row } from "antd";

const DefaultMeasuresForm = ({ form, onCancel, onFinish }: any) => {
  return (
    <div style={{ maxWidth: "60rem", margin: "auto" }} title="Default Measures">
      <Form
        className="form-allunox"
        layout="vertical"
        name="defaultMeasures"
        form={form}
        onFinish={onFinish}
      >
        <Row gutter={24}>
          {/*      <Col span={24}>
            <Form.Item
              style={{ marginBottom: "39px" }}
              label="Measurement before Brick"
              name="measurement_before_brick"
              rules={[{ required: true, message: "This field is required" }]}
            >
              <Radio.Group>
                <Radio value={1}>Yes</Radio>
                <Radio value={0}>No</Radio>
              </Radio.Group>
            </Form.Item>
            <Divider />
            <Form.Item
              label="Brick thickness"
              name="brick_thickness"
              rules={[{ required: true, message: "This field is required" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Max Spacing of Intermediate"
              name="max_spacing_intermediate"
              rules={[{ required: true, message: "This field is required" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Distance from Slab Edge"
              name="distance_slab_edge"
              rules={[{ required: true, message: "This field is required" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Distance from Wall to Glass"
              name="distance_wall_glass"
              rules={[{ required: true, message: "This field is required" }]}
            >
              <Input />
            </Form.Item>
          </Col> */}
          <Col span={24}>
            <Form.Item
              label="Spacing Between Glass"
              name="spacing_between_glass"
              rules={[{ required: true, message: "This field is required" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Spacing Between Glass and Corner"
              name="spacing_between_glass_corner"
              rules={[{ required: true, message: "This field is required" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Height of Glass"
              name="height_glass"
              rules={[{ required: true, message: "This field is required" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Center of Intermediate Post to Wall"
              name="center_intermediate_post_wall"
              rules={[{ required: true, message: "This field is required" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Center of Intermediate Post to Edge"
              name="center_intermediate_post_edge"
              rules={[{ required: true, message: "This field is required" }]}
            >
              <Input />
            </Form.Item>

            {/*    <Form.Item
              label="Max Length per piece"
              name="max_length_per_piece"
              rules={[{ required: true, message: "This field is required" }]}
            >
              <Input type="number" />
            </Form.Item> */}
          </Col>
        </Row>

        <Form.Item style={{ textAlign: "end", marginBottom: 0, marginTop: 10 }}>
          {/*       <Button
            onClick={onCancel}
            style={{ marginRight: 10 }}
            htmlType="button"
            type="default"
          >
            Cancel
          </Button> */}

          <Button htmlType="submit" type="primary">
            Update Default Measures
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default DefaultMeasuresForm;
