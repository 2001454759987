import { Select, Button } from "antd";
import { useEffect, useState, useRef } from "react";

import { useAppSelector } from "../../../redux/hooks";
const { Option } = Select;

interface BalconyTypeSelectProps {
  onBalconyTypeChange: (value: any) => void;
  balconyType?: any;
  disabled?: boolean;
}

const BalconyTypeSelect = ({
  onBalconyTypeChange,
  balconyType,
  disabled,
}: BalconyTypeSelectProps) => {
  const itemRefs = useRef<(HTMLDivElement | null)[]>([]);

  const balconyTypes = useAppSelector(
    (state) => state.balconyTypesReducer.balconyTypes
  );
  const [scrollIndex, setScrollIndex] = useState(balconyType);

  const [selectedBalcony, setSelectedBalcony] = useState(balconyType);

  useEffect(() => {
    setScrollIndex(balconyType);
    const indexToScrollTo = balconyType;
    if (itemRefs.current[indexToScrollTo]) {
      itemRefs?.current[indexToScrollTo]?.scrollIntoView({
        behavior: "smooth",
        inline: "center",
      });
    }
    setSelectedBalcony(balconyType);
    onBalconyTypeChange(balconyType);
  }, [balconyType, scrollIndex]);
  const handleChange = (value: any) => {
    onBalconyTypeChange(value);
  };
  const handleClick = (id: number) => {
    setSelectedBalcony(id);
    onBalconyTypeChange(id);
  };
  const handleScrollToElement = (index: number) => {
    setScrollIndex(index);
  };

  return (
    <>
      <div
        style={{
          display: "list-item",
          overflowX: "auto",
          whiteSpace: "nowrap",
          width: "100%",
        }}
      >
        {balconyTypes.map((balconyType: any) => (
          <>
            <Button
              key={balconyType.id}
              onClick={() => handleClick(balconyType.id)}
              id={balconyType.id}
              ref={(el: any) => {
                itemRefs.current[balconyType.id] = el;
              }}
              style={{
                margin: "5px",
                backgroundColor:
                  selectedBalcony === balconyType.id ? "#fff" : "#f5f5f5",
                color: selectedBalcony === balconyType.id ? "#000" : "#000",
                border:
                  selectedBalcony === balconyType.id ? "1px solid #000" : "",
                height: "100px",
                width: "47%",
              }}
              disabled={disabled}
            >
              <small> {balconyType.name}</small>
              <br />
              <img
                src={require(`../../../static/img/balconies/${balconyType?.name}.svg`)}
                style={{
                  width: "24%",
                  height: "auto",
                  transform: "rotate(90deg)",
                }}
                alt={balconyType.name}
              />
            </Button>
          </>
        ))}
      </div>
    </>
  );
};

export default BalconyTypeSelect;
