export const getAddressFormat = (address: any = {}) => {
  const {
    address_line_1,
    address_line_2,
    city,
    country,
    postal_code,
    province,
  } = address;

  return (
    (address_line_1 || "") +
    (address_line_2 ? " " + address_line_2 : "") +
    " " +
    (postal_code || "") +
    ", " +
    (city || "") +
    ", " +
    (province || "") +
    ", " +
    (country || "")
  );
};

export const getDateString = (date: string) => {
  return new Intl.DateTimeFormat("en-CA", {
    dateStyle: "medium",
    timeStyle: "short",
  }).format(new Date(date));
};
