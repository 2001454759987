import { actions } from "./actions";
const {
  ACTION_BEGIN,
  ERROR,
  SUCCESS_BALCONYTYPES,
  SUCCESS_BALCONYTYPE,
  CLEAN_BALCONYTYPE,
} = actions;

const initialState = {
  loading: false,
  loaderBalconyTypes: false,
  balconyTypes: [],
  balconyType: {},
};

export default function responseReducer(state = initialState, action: any) {
  const { type, data, err } = action;

  switch (type) {
    case ACTION_BEGIN:
      const stateClone = { ...state, ...data };
      return {
        ...stateClone,
      };
    case SUCCESS_BALCONYTYPES:
      return {
        ...state,
        balconyTypes: data,
      };
    case SUCCESS_BALCONYTYPE:
      return {
        ...state,
        balconyType: data,
      };

    case CLEAN_BALCONYTYPE:
      return {
        ...state,
        balconyType: {},
      };
    case ERROR:
      return {
        ...state,
        loading: false,
        loaderBalconyTypes: false,
        error: err,
      };
    default:
      return state;
  }
}
