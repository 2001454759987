import { Button, Col, Form, Input, Row } from "antd";
import { useNavigate } from "react-router-dom";
import {
  actionLogin,
  actionLoginSuper,
  actionValidCode,
  checkUserRole,
} from "../../redux/auth/actions";
import { useAppDispatch } from "../../redux/hooks";
import { useIsAuthenticated, useSignIn } from "react-auth-kit";
import { useEffect, useState } from "react";
import { validateEmail, validateEmailPhone } from "../../utils/validations";
import { SupportTopWrap } from "./style";
import ReactCodeInput from "react-code-input";
import { ROLES } from "../../utils/roles";

let timeout: any;

/* eslint-disable react-hooks/exhaustive-deps */
const Login = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isAuthenticated = useIsAuthenticated();
  const [form] = Form.useForm();
  const [authenticated, setAuthenticated] = useState(isAuthenticated());
  const [isValid, setIsValid] = useState(true);
  const [isSupport, setIsSupport] = useState(false);
  const [viewCode, setViewCode] = useState(false);
  const [loading, setLoading] = useState(false);
  const signIn = useSignIn();

  useEffect(() => {
    if (authenticated) navigate("/dashboard");
  }, [authenticated]);

  const onFinish = async (data: any) => {
    if (!loading) {
      if (!isSupport) {
        const response: any = await dispatch(actionLogin(data));

        if (response) {
          setViewCode(true);
        }
      } else {
        data.email = data.value;
        const response: any = await dispatch(actionLoginSuper(data));

        if (response?.data) {
          const params: any = {
            tokenType: "Bearer",
            token: response?.data.token,
            expiresIn: response?.data.expiresIn,
            authState: response?.data.authState,
          };

          const isAuthenticated = signIn(params);
          setAuthenticated(isAuthenticated);
        }
      }
    }
  };

  const checkUser = (event: any) => {
    clearTimeout(timeout);

    setLoading(true);
    timeout = setTimeout(async () => {
      if (validateEmail(event.target.value)) {
        const response: any = await dispatch(checkUserRole(event.target.value));

        if (response?.data === ROLES.ROLE_SUPER_ADMIN) {
          setIsSupport(true);
        }
      } else {
        setIsSupport(false);
      }

      setLoading(false);
    }, 2000);
  };

  const onInputCodeChange = async (code: any) => {
    if (code.length === 6) {
      const response: any = await dispatch(
        actionValidCode({
          login_code: code,
          value: form.getFieldValue("value"),
        })
      );

      if (response?.data) {
        setIsValid(true);
        const params: any = {
          tokenType: "Bearer",
          token: response?.data.token,
          expiresIn: response?.data.expiresIn,
          authState: response?.data.authState,
        };

        const isAuthenticated = signIn(params);
        setAuthenticated(isAuthenticated);
      } else {
        setIsValid(false);
      }
    }
  };

  const onResendEmail = (flag: any) => {
    if (flag) {
      setViewCode(false);
    }
  };

  return (
    <div className="login">
      {viewCode ? (
        <SupportTopWrap>
          <div className="sDash-support-container">
            <Row align="middle">
              <Col xs={24}>
                <div className="sDash_support-content text-center">
                  <p>
                    Please enter the code we have sent to:{" "}
                    <b>{form.getFieldValue("value")}</b>
                  </p>
                </div>
              </Col>

              <Col xs={24}>
                <ReactCodeInput
                  name="code"
                  inputMode="numeric"
                  isValid={isValid}
                  type="text"
                  fields={6}
                  onChange={onInputCodeChange}
                />

                <p className="text">
                  Did not you get the code?
                  <Button
                    type="text"
                    onClick={() => {
                      setIsValid(true);
                      onResendEmail(true);
                    }}
                  >
                    resend code
                  </Button>
                </p>
              </Col>
            </Row>
          </div>
        </SupportTopWrap>
      ) : (
        <Form form={form} name="login" layout="vertical" onFinish={onFinish}>
          <Form.Item
            name="value"
            label="Phone or email"
            style={{ maxWidth: "18rem", margin: "auto", marginBottom: "24px" }}
            rules={[
              { required: true, message: "Field required" },
              () => ({
                validator(_rule, value) {
                  if (!value || validateEmailPhone(value)) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject(
                      "Mail Address / Phone number is not valid, Please provide a valid Email or Phone number"
                    );
                  }
                },
                validateTrigger: "onSubmit",
              }),
            ]}
          >
            <Input onChange={checkUser} autoComplete="nope" />
          </Form.Item>
          {isSupport ? (
            <Form.Item
              name="password"
              label="Password"
              style={{
                maxWidth: "18rem",
                margin: "auto",
                marginBottom: "24px",
              }}
              rules={[{ required: true, message: "Field required" }]}
            >
              <Input type="password" autoComplete="off" />
            </Form.Item>
          ) : (
            <></>
          )}

          <Form.Item>
            <br />
            <Button
              style={{ minWidth: "11rem" }}
              size="large"
              type="primary"
              htmlType="submit"
            >
              Login
            </Button>
          </Form.Item>
        </Form>
      )}
    </div>
  );
};

export default Login;
