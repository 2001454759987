import { Select } from "antd";

const VersionSelect = ({
  currentVersion,
  versions,
  changeVersion,
}: {
  currentVersion: any;
  versions: [];
  changeVersion: any;
}) => {
  const options = versions.map((version: any) => {
    return {
      value: version.version,
      label: `${version?.version} - ${version?.date} - ${version.measurement_brick} bricks`,
    };
  });
  const handleChange = (value: any) => {
    changeVersion(value);
  };
  return (
    <>
      {versions.length != 0 && (
        <Select
          defaultValue={currentVersion}
          onChange={handleChange}
          options={options || []}
          style={{ width: "100%" }}
          value={currentVersion}
          fieldNames={{ label: "label", value: "value" }}
        />
      )}
    </>
  );
};

export default VersionSelect;
