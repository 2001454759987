import { cloneBalcony } from "../../../redux/projects/actions";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { Checkbox, Row, Col, Space, Typography, Button } from "antd";
const { Title, Text } = Typography;

interface balconyCloneProps {
  balconyToClone: Object | any;
  balconiesOfProject: Array<any>;
  onCloseModal: () => void;
  currentProjectVersion?: number;
}

const BalconyClone = ({
  balconyToClone,
  balconiesOfProject,
  onCloseModal,
  currentProjectVersion,
}: balconyCloneProps) => {
  const dispatch = useAppDispatch();

  const [selectedBalconies, setSelectedBalconies] = useState<any[]>([]);

  const onChange = (checkedValues: any) => {
    setSelectedBalconies(checkedValues);
  };

  const onSubmit = () => {
    dispatch(
      cloneBalcony(balconyToClone.id, {
        to: selectedBalconies,
        version: currentProjectVersion,
      })
    );
    onCloseModal();
    /* close modal */
  };

  return (
    <div>
      <Space
        direction="horizontal"
        style={{
          width: "100%",
          justifyContent: "left",
        }}
      >
        <Title level={4} color="blue" style={{ margin: 0, color: "#07388d" }}>
          Balcony to clone: {balconyToClone.full_name}
        </Title>
      </Space>

      <Space direction="horizontal" style={{ width: "100%" }}>
        <Text>Select balconies to clone</Text>
      </Space>
      <Checkbox.Group
        style={{ width: "100%" }}
        defaultValue={[]}
        onChange={onChange}
      >
        <Row style={{ width: "100%" }}>
          {balconiesOfProject.map((floor) => {
            return (
              <Col span={6}>
                <Title
                  level={5}
                  color="blue"
                  style={{ margin: 0, color: "#07388d" }}
                >
                  {floor.name}
                </Title>
                {floor.balconies.map((balcony: any) =>
                  balcony.id !== balconyToClone.id ? (
                    <div key={balcony.id}>
                      <Space
                        direction="horizontal"
                        style={{ width: "100%", justifyContent: "left" }}
                      >
                        <Checkbox value={balcony.id}>
                          {balcony.full_name}
                        </Checkbox>
                      </Space>
                    </div>
                  ) : (
                    <></>
                  )
                )}
              </Col>
            );
          })}
        </Row>
      </Checkbox.Group>

      {/* submit button */}
      <Space
        direction="horizontal"
        style={{
          width: "100%",
          justifyContent: "right",
          marginTop: "20px",
        }}
      >
        <Button type="primary" onClick={onSubmit} style={{ width: "100%" }}>
          Clone
        </Button>
      </Space>
    </div>
  );
};

export default BalconyClone;
